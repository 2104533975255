import {Button, DatePicker, Form, Radio, Select} from "antd";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import FormPage from "../common/FormPage";
import {requiredField} from "../common/common";

const date2dayjs = (d) => d ? dayjs(d, "YYYY-MM-DD") : undefined


export default function NewContract() {
    const [contractTypes, setContractTypes] = useState([])
    const [terminationReasons, setTerminationReasons] = useState([])
    const [mansions, setMansions] = useState([])
    const [contractTypeDueDate, setContractTypeDueDate] = useState(false)
    const navigate = useNavigate();
    const {id} = useParams()

    useEffect(() => {
        [
            {p: "contractType", f: setContractTypes},
            {p: "mansion", f: setMansions},
            {p: "terminationReason", f: setTerminationReasons}
        ].forEach(x => axiosInstance.get(x.p).then(({data}) => x.f(data)))
    }, []);

    const onFormFinish = (f) => {
        const data = {
            ...f,
            startDate: f.startDate?.format("YYYY-MM-DD"),
            dueDate: f.dueDate?.format("YYYY-MM-DD"),
            endDate: f.startDate?.format("YYYY-MM-DD"),
        }
        axiosInstance.post(`employee/${id}/contract`, data)
            .then(() => navigate(-1));
    }


    return <FormPage
        title={"Nuovo contratto"}
        onFinish={onFormFinish}>
        <Form.Item label={"Data assunzione"} name={"startDate"} rules={[requiredField]}>
            <DatePicker style={{minWidth: '100%'}}/>
        </Form.Item>
        <Form.Item label={"Tipo contratto"} name={"contractType"} rules={[requiredField]}>
            <Select
                placeholder={"Seleziona tipo di contratto"}
                onSelect={v => {
                const dueDate = contractTypes.filter(x => x.id === v)[0]?.hasDueDate || false
                setContractTypeDueDate(dueDate)
            }}
                    options={contractTypes.map(x => ({label: x.type, value: x.id}))}/>
        </Form.Item>
        {contractTypeDueDate && (
            <Form.Item label={"Data di scadenza contratto"} name={"dueDate"} rules={[requiredField]}>
                <DatePicker style={{minWidth: '100%'}}/>
            </Form.Item>
        )}
        <Form.Item label={"Mansioni"} name={"mansions"} rules={[requiredField]}>
            <Select
                placeholder={"Seleziona le mansioni"}
                mode={"multiple"} options={mansions.map(x => ({label: x.name, value: x.id}))}/>
        </Form.Item>
        <Form.Item hasFeedback label={"Ora di viaggio"} name={"hasTravelHour"} rules={[requiredField]}>
            <Radio.Group>
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item wrapperCol={{span: 24}} style={{textAlign: 'end'}}>
            <Button type={"primary"} htmlType={"submit"}>Salva modifiche</Button>
        </Form.Item>
    </FormPage>
}
