import React, {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import axiosInstance from "../common/api";
import {Button, Col, Divider, message, Row, Select, Space, Table} from "antd";
import Title from "antd/lib/typography/Title";
import Search from "antd/lib/input/Search";


export default function CriticalIssues() {
    const [result, setResult] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    const page = Number(searchParams.get("p") ?? "0");
    const pageSize = Number(searchParams.get("ps") ?? "10");
    const query = searchParams.get("q")
    const types = [
        {title: "DPI", value: 'dpi'},
        {title: "Documento", value: 'attachment'},
        {title: "Corso di formazione", value: 'course'}
    ]
    const typesFilter = searchParams.get("types")?.split(',') ?? []

    const [searchQuery, setSearchQuery] = useState(query)

    const filterParams = [
        query && ("q=" + query),
        page && ("page=" + page),
        pageSize && ("pageSize=" + pageSize),
        ...typesFilter.map(x => "types=" + x)
    ].filter(x => !!x).join("&")

    const fetch = () => {
        setLoading(true)
        return axiosInstance.get("criticalissue?" + filterParams)
            .then(res => {
                setResult(res.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        fetch()
    }, [searchParams]);


    const columns = [
        {
            title: 'Nominativo',
            ellipsis: true,
            key: 'name',
            width: 170,
            render: (_, record) => <Link to={`/employee/${record.id.id}`}><u>{record.lastName} {record.firstName}</u></Link>
        },
        {
            title: 'Tipo',
            key: 'type',
            dataIndex: 'type',
            filters: types.map(x => ({text: x.title, value: x.value})),
            filterSearch: true,
            ellipsis: true,
            filteredValue: typesFilter,
            width: 150,
            render: x => types.filter(z => z.value === x)[0]?.title
        },
        {
            title: 'Elemento mancante',
            key: 'description',
            dataIndex: 'description',
            ellipsis: true,
            width: 400,
        },
    ];

    return <Space direction={"vertical"} style={{width: '100%'}} size={16}>
        <Title level={2} style={{margin: 0}}>Criticità</Title>
        <Row style={{marginTop: 30}}>
            <Col span={12}>
                <Space size={20} wrap>
                    <Search value={searchQuery} onChange={({target}) => setSearchQuery(target.value)}
                            placeholder="Cerca" allowClear
                            style={{width: 200}}
                            onSearch={text => {
                                searchParams.set("q", text);
                                searchParams.delete("p")
                                setSearchParams(searchParams, {replace: true})
                            }}/>
                </Space>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type={"text"} danger onClick={() => {
                    setSearchParams(undefined, {replace: true})
                    setSearchQuery("");
                }}>Cancella filtri</Button>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table columns={columns}
                       tableLayout={"fixed"}
                       dataSource={result?.data.map(x => ({...x, key: `${x.id.id}_${x.id.typeId}`}))}
                       loading={loading}
                       onChange={(pagination, filters, sorter) => {
                           searchParams.set("p", "" + (pagination.current - 1))
                           searchParams.set("ps", "" + pagination.pageSize)
                           if (filters.type && filters.type.length > 0)
                               searchParams.set("types", filters.type.join(","));
                           else
                               searchParams.delete("types");

                           setSearchParams(searchParams, {replace: true})
                       }}
                       pagination={{
                           total: result?.count,
                           showTotal: count => `Totale: ${count}`,
                           pageSize: pageSize,
                           current: page + 1,
                       }}
                       scroll={{x: columns.reduce((acc, x) => x.width + acc, 0)}}
                />
            </Col>
        </Row>
    </Space>;
}