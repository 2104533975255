import {Button, Modal, Result} from "antd";
import {useNavigate} from "react-router-dom";


export default function ({isOpen, handleOk, handleCancel}) {
    return <Modal open={isOpen} onOk={handleOk} onCancel={handleCancel} centered>
        <Result
            status="success"
            title="P.O.S. generato correttamente"
            subTitle="Si vuole procedre con l'export dei documenti per i dipendenti selezionati?"
        />
    </Modal>
}