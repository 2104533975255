import {useState} from "react";
import ConfirmPaycheck from "./ConfirmPaycheck";
import {useNavigate} from "react-router-dom";
import {Space} from "antd";
import Title from "antd/lib/typography/Title";


export default function ConfirmPaychecks({data}) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const navigate = useNavigate();

    console.log(currentIndex)
    if (currentIndex === data.length && data.length > 0)
        navigate(-1)

    return <Space size={16} direction={"vertical"} style={{width: '100%'}}>
        <Title level={3} style={{margin: 0}}>Cedolini che richiedono attenzione</Title>
        <div style={{width: '100%', fontSize: '1.5em', textAlign: "end"}}>{`${currentIndex + 1}/${data.length}`}</div>
        {currentIndex < data.length &&
            <ConfirmPaycheck data={data[currentIndex]} onFinish={() => setCurrentIndex(currentIndex + 1)
            }/>
        }
    </Space>
}