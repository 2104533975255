import { Scanner } from "@yudiel/react-qr-scanner";
import { Card, NavBar } from "antd-mobile";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Portal } from "react-portal";

export const ScanPage = forwardRef(({ onChange }, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        open: () => setVisible(!visible),
        close: () => setVisible(false),
    }));
    useEffect(() => {
        if(visible){
            document.body.style.overflow = "hidden"
        }else
            document.body.style.overflow = "inherit"
    },[visible])

    return (
        <div>
            {visible && (
                <Portal>
                    <div
                        onClick={(evt) => evt.stopPropagation()}
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            minWidth: "100%",
                            minHeight: "100%",
                            zIndex: 999,
                            display: "flex",
                            flexDirection: "column",
                            background: "white",
                        }}
                    >
                        <div>
                            <NavBar onBack={() => setVisible(false)}>Scansione Badge</NavBar>
                            <div style={{ marginTop: 30 }}>
                                <Scanner
                                    onScan={([value]) => {
                                        onChange?.(parseInt(value.rawValue));
                                        setVisible(false);
                                    }}
                                    components={{ finder: false }}
                                />
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    );
});
