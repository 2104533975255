import {Button, Checkbox, DatePicker, Form, Input, message, Radio, Select, Spin, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {requiredField} from "../common/common";
import {UploadOutlined} from "@ant-design/icons";
import axiosInstance from "../common/api";
import {useNavigate, useParams} from "react-router-dom";
import Dragger from "antd/lib/upload/Dragger";
import FormPage from "../common/FormPage";
import {useForm} from "antd/lib/form/Form";
import dayjs from "dayjs";


export default function EditCourse() {

    const [employees, setEmployees] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true)
    const {id} = useParams();
    const [form] = useForm();

    useEffect(() => {
        [{u: "courseType", f: setCourseTypes}, {u: "employeeCompact", f: setEmployees}].forEach(x => {
            axiosInstance.get(x.u)
                .then(({data}) => x.f(data))
        });

        axiosInstance.get("course/" + id)
            .then(({data}) => form.setFieldsValue({
                ...data,
                date: dayjs(data.date,"YYYY-MM-DD"),
                employee: data.employee.id,
                courseType: data.courseType.id
            }))
            .finally(() => setDataLoading(false));
    }, []);

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onFormFinish = (form) => {
        setLoading(true)
        const data = {
            ...form,
            date: form.date.format("YYYY-MM-DD")
        }
        axiosInstance.put("course/" + id,data)
            .then(() => {
                navigate(-1)
                message.success("Corso modificato con successo");
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return <Spin spinning={dataLoading}>
        <FormPage
            title={"Modifica corso"}
            onFinish={onFormFinish}
            form={form}
        >
            <Form.Item name={"employee"} label={"Dipendente"} rules={[requiredField]}>
                <Select filterOption={filterOption} showSearch placeholder={"Seleziona il dipendente"}
                        options={employees.map(x => ({label: x.fullName, value: x.id}))}/>
            </Form.Item>
            <Form.Item name={"courseType"} label={"Nome corso"} rules={[requiredField]}>
                <Select filterOption={filterOption} showSearch placeholder={"Seleziona il tipo di corso"}
                        options={courseTypes.map(x => ({label: x.title, value: x.id}))}/>
            </Form.Item>
            <Form.Item name={"isBase"} label={"Tipo di attestato"}>
                <Radio.Group>
                    <Radio value={true}>Base</Radio>
                    <Radio value={false}>Aggiornamento</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={"date"} label={"Data"} rules={[requiredField]}>
                <DatePicker format={"DD/MM/YYYY"} style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item wrapperCol={{span: 24}} style={{textAlign: "end"}}>
                <Button htmlType={"submit"} type={"primary"} loading={loading}>Carica</Button>
            </Form.Item>

        </FormPage>
    </Spin>
}