import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    Row,
    Segmented,
    Space,
} from 'antd';
import Search from "antd/lib/input/Search";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
    PlusOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import DownloadTimesheetButton from "./DownloadTimesheetButton";
import dayjs from "dayjs";
import AttendanceTabularView from "./AttendanceTabularView";
import AttendanceNormalView from "./AttendanceNormalView";
import ManualSyncButton from './ManualSyncButton';
import AttendanceSummaryButton from './AttendanceSummaryButton';

const { RangePicker } = DatePicker;

const NORMAL_VIEW = 'NORMAL';
const TABLE_VIEW = 'TABLE';


export const AttendanceMappings = [
    {
        title: 'Presenza',
        type: 'NORMAL'
    },
    {
        title: 'Malattia',
        type: 'ILLNESS'
    },
    {
        title: 'Permesso',
        type: 'PERMIT'
    },
    {
        title: 'Ferie',
        type: 'HOLIDAY'
    },
    {
        title: 'Assenza non giust.',
        type: 'UNJUSTIFIED'
    },

]

const str2date = str => str && dayjs(str, "YYYY-MM-DD")

export default function() {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDateStr = searchParams.get("startDate")
    const endDateStr = searchParams.get("endDate")
    const r = str2date(startDateStr) && str2date(endDateStr) && [str2date(startDateStr), str2date(endDateStr)]
    const [searchQuery, setSearchQuery] = useState(searchParams.get("q"))
    const [range, setRange] = useState(r)
    const selectedView = searchParams.get("view") ?? "NORMAL";


    const newOptions = AttendanceMappings.map((x, i) => ({
        key: i, label: <Link to={`new?type=${x.type}`}>{x.title}</Link>
    }))

    return <Space direction={"vertical"} style={{ width: '100%' }} size={16}>
        <Row>
            <Col span={6}>
                <Title level={2} style={{ margin: 0 }}>Presenze</Title>
            </Col>
            <Col span={18}>
                <Space wrap style={{ width: '100%', justifyContent: "end" }}
                    split={<Divider type={"vertical"} />}>
                    <ManualSyncButton/>
                    <AttendanceSummaryButton/>
                    <DownloadTimesheetButton />
                    <Dropdown menu={{ items: newOptions }} placement={"bottom"}>
                        <Button type={"primary"}
                            icon={<PlusOutlined />}
                            style={{ width: 200 }}>
                            Nuovo
                        </Button>
                    </Dropdown>
                </Space>
            </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
            <Col span={12}>
                <Space size={20} wrap>
                    <Search value={searchQuery} onChange={({ target }) => setSearchQuery(target.value)}
                        placeholder="Cerca" allowClear
                        style={{ width: 200 }}
                        onSearch={text => {
                            searchParams.set("q", text);
                            searchParams.delete("p")
                            setSearchParams(searchParams, { replace: true })
                        }} />
                    <RangePicker format={"DD/MM/YYYY"} value={range} onChange={(r) => {
                        setRange(r);
                        if (r && r[0] && r[1]) {
                            searchParams.set("startDate", r[0].format("YYYY-MM-DD"))
                            searchParams.set("endDate", r[1].format("YYYY-MM-DD"))
                            searchParams.delete("p")
                        } else {
                            searchParams.delete("startDate")
                            searchParams.delete("endDate")
                            searchParams.delete("p")
                        }
                        setSearchParams(searchParams, { replace: true })
                    }} />
                </Space>
            </Col>
            <Col span={12} style={{ textAlign: "end" }}>
                <Button type={"text"} danger onClick={() => {
                    setSearchParams(undefined, { replace: true })
                    setSearchQuery("");
                    setRange(null);
                }}>Cancella filtri</Button>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Segmented options={['Vista normale', 'Vista tabellare']}
                    value={selectedView === NORMAL_VIEW ? 'Vista normale' : 'Vista tabellare'}
                    onChange={x => {
                        searchParams.set('view', x === 'Vista normale' ? NORMAL_VIEW : TABLE_VIEW)
                        setSearchParams(searchParams)
                    }} />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                {selectedView === NORMAL_VIEW ? <AttendanceNormalView /> : <AttendanceTabularView />}
            </Col>
        </Row>
    </Space>;
}
