import { Button, Col, FloatButton, Layout, List, message, Row, Skeleton } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import UserAvatar from "../common/UserAvatar";
import InfiniteScroll from "react-infinite-scroll-component";

export default function EmployeeAttendancePage() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const pageSize = 20;
    const mData = data;

    const fetchData = () => {
        setPageLoading(true);
        axiosInstance
            .get(`selfattendance?page=${page}&pageSize=${pageSize}`)
            .then(({ data }) => {
                setData([...mData, ...data.data]);
                setHasMore(data.data.length === pageSize);
            })
            .finally(() => setPageLoading(false));
    };

    useEffect(() => {
        if (page == null) setPage(0);
        else fetchData();
    }, [page]);

    const loadMoreData = () => {
        if (pageLoading) {
            return;
        }
        setPage(page + 1);
    };

    const post = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        axiosInstance
            .post("selfattendance", { lat: latitude, lng: longitude })
            .then(() => {
                message.success("Timbratura registrata correttamente");
            })
            .then(() => {
                setData([]);
                setPage(null);
            })
            .finally(() => setLoading(false));
    };

    function error() {
        message.error(
            "Impossibile procedere. L'accesso alla posizione è richiesto per la timbratura. Ricarica la pagina oppure abilità i permessi",
            5,
        );
        setLoading(false);
    }

    const getLocationAndSend = () => {
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(post, error);
        } else {
            message.error("Impossibile procedere. Accesso alla posizione non supportato.", 5);
        }
    };

    return (
        <Layout style={{ minHeight: "100vh", background: "white" }}>
            <Header
                style={{
                    zIndex: 100,
                    top: 0,
                    width: "100%",
                    position: "sticky",
                }}
            >
                <Row>
                    <Col flex={"150px"} style={{ color: "white" }}>
                        <h2 style={{ margin: 0 }}>Omega HR</h2>
                    </Col>
                    <Col
                        flex={1}
                        style={{ justifyContent: "end", alignItems: "center", display: "flex" }}
                    >
                        <UserAvatar />
                    </Col>
                </Row>
            </Header>
            <Content style={{ padding: 16 }}>
                <Row>
                    <Col flex={"160px"}>
                        <Title level={3} style={{ marginBottom: 0 }}>
                            Timbrature
                        </Title>
                    </Col>
                    <Col flex={"auto"}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "end",
                            }}
                        >
                            <Button
                                type={"primary"}
                                loading={loading}
                                icon={<PlusOutlined />}
                                onClick={getLocationAndSend}
                            >
                                Nuova timbratura
                            </Button>
                        </div>
                    </Col>
                </Row>

                <div style={{ width: "100%", marginTop: 20 }}>
                    <InfiniteScroll
                        dataLength={data.length}
                        next={loadMoreData}
                        hasMore={hasMore}
                        loader={
                            <Skeleton
                                avatar
                                paragraph={{
                                    rows: 1,
                                }}
                                active
                            />
                        }
                    >
                        <List
                            dataSource={data}
                            renderItem={(item) => (
                                <List.Item>
                                    {dayjs(item.ts).format("dddd DD MMMM YYYY")} alle{" "}
                                    {dayjs.utc(item.ts).tz("Europe/Rome").format("HH:mm")}
                                </List.Item>
                            )}
                        />
                    </InfiniteScroll>
                </div>
            </Content>
        </Layout>
    );
}
