import React, {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import axiosInstance from "../common/api";
import {Button, Checkbox, Col, Divider, Row, Space, Table, Tag, Tooltip} from "antd";
import {ExportOutlined, PlusOutlined, WarningTwoTone} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import Search from "antd/lib/input/Search";
import AssignEmployeeModal from "./AssignEmployeeModal";


export default function Users(){
    const [result, setResult] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    const page = Number(searchParams.get("p") ?? "0");
    const pageSize = Number(searchParams.get("ps") ?? "10");
    const query = searchParams.get("q")
    const [searchQuery, setSearchQuery] = useState(query)

    const [selectedUserId, setSelectedUserId] = useState()


    const filterParams = [
        query && ("query=" + query),
        page && ("page=" + page),
        pageSize && ("pageSize=" + pageSize),
    ].filter(x => x).join("&")

    const fetchData = () => {
        setLoading(true)
        axiosInstance.get("user?" + filterParams)
            .then(res => {
                setResult(res.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [searchParams]);



    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            fixed: 'left',
            render: id => <a onClick={() => setSelectedUserId(id)}><u>{id}</u></a>
        },
        {
            title: 'Nome',
            dataIndex: 'firstName',
            fixed: 'left',
            width: 200,
            key: 'firstName',
        },
        {
            title: 'Cognome',
            key: 'lastName',
            dataIndex: 'lastName',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Dipendente associato',
            render: (_,record) => record.employeeId ? record.employeeFirstName + " " + record.employeeLastName : '-'
        }
    ];

    return <Space direction={"vertical"} style={{width: '100%'}} size={16}>
        <AssignEmployeeModal userId={selectedUserId}
                             onCancel={() => setSelectedUserId(null)}
                             onFinish={() => {setSelectedUserId(null); fetchData()}}/>
        <Row>
            <Col span={6}>
                <Title level={2} style={{margin: 0}}>Utenti</Title>
            </Col>
        </Row>
        <Row style={{marginTop: 30}}>
            <Col span={12}>
                <Space size={20} wrap>
                    <Search value={searchQuery} onChange={({target}) => setSearchQuery(target.value)}
                            placeholder="Cerca" allowClear
                            style={{width: 200}}
                            onSearch={text => {
                                searchParams.set("q", text);
                                searchParams.delete("p")
                                setSearchParams(searchParams, {replace: true})
                            }}/>
                </Space>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type={"text"} danger onClick={() => {
                    setSearchParams(undefined, {replace: true})
                    setSearchQuery("");
                }}>Cancella filtri</Button>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table columns={columns}
                       tableLayout={"fixed"}
                       dataSource={result?.data.map(x => ({...x, key: x.id}))}
                       loading={loading}
                       onChange={(pagination, filters, sorter) => {
                           searchParams.set("p", "" + (pagination.current - 1))
                           searchParams.set("ps", "" + pagination.pageSize)
                           setSearchParams(searchParams, {replace: true})
                       }}
                       pagination={{
                           total: result?.count,
                           showTotal: (total) => `Totale: ${total}`,
                           pageSize: pageSize,
                           current: page + 1,
                       }}
                       scroll={{x: 700}}
                />
            </Col>
        </Row>
    </Space>;
}