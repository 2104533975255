import {Button, DatePicker, Form, Popover, Space} from "antd";
import {FileExcelOutlined} from "@ant-design/icons";
import {downloadFile, requiredField} from "../common/common";
import {useForm} from "antd/lib/form/Form";
import {useState} from "react";

const {RangePicker} = DatePicker
export default function () {
    const [open, setOpen] = useState(false)
    const [form] = useForm();
    const [downloading, setDownloading] = useState(false)
    const onFormFinish = ({range}) => {
        setDownloading(true)
        const start = range[0].format("YYYY-MM-DD");
        const end = range[1].format("YYYY-MM-DD");
        downloadFile(`/timesheet?startDate=${start}&endDate=${end}`)
            .then(() => form.resetFields())
            .then(() => setOpen(false))
            .finally(() => setDownloading(false))
    }
    const content = <Form form={form} onFinish={onFormFinish} layout={"vertical"}>
        <Form.Item name={"range"} label={"Data di inizio e fine"} rules={[requiredField]}>
            <RangePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item style={{textAlign: "end", marginBottom: 10}}>
            <Button type={"primary"} htmlType={"submit"}>Scarica</Button>
        </Form.Item>
    </Form>

    return <Popover open={open}
                    onOpenChange={(v) => {
                        setOpen(v);
                        form.resetFields()
                    }} content={content} title={"Scarica foglio presenze"}
                    trigger="click">
        <Button type={"link"} icon={<FileExcelOutlined/>} loading={downloading}>Foglio presenze</Button>
    </Popover>
}