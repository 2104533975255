import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {Button, Col, Descriptions, List, Row, Space, Spin, Tabs} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import BackTitle from "../common/BackTitle";
import {tab} from "@testing-library/user-event/dist/tab";
import PermissionRequired from "../common/PermissionRequired";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";


export default function Mansion() {
    const {id} = useParams()
    const [mansion, setMansion] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const tabs = [
        ['1', 'Corsi obbligatori', mansion.courseTypes ?? []],
        ['2', 'DPI obbligatori', mansion.dpiTypes ?? []],
        ['3', 'Documenti obbligatori', mansion.attachmentTags ?? []]
    ].map(x => ({
        key: x[0],
        label: x[1],
        children: <List dataSource={x[2]}
                        renderItem={z => <List.Item>{z.name}</List.Item>}/>,
    }))

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`mansion/${id}`)
            .then(({data}) => setMansion(data))
            .finally(() => setLoading(false))
    }, []);

    const deleteMansion = () => {
        setLoading(true)
        axiosInstance.delete(`mansion/${id}`)
            .then(() => navigate(-1))
            .finally(() => setLoading(false))
    }


    return <Spin spinning={loading}>
        <Row>
            <Col flex={"400px"}>
                <BackTitle padding={0} title={`Dettagli mansione`}/>
            </Col>
            <Col flex={1} style={{textAlign: "end"}}>
                <Space>
                    <PermissionRequired name={"mansion.update"}>
                        <Button
                            icon={<EditOutlined/>}
                            onClick={() => navigate('edit')}>Modifica</Button>
                    </PermissionRequired>
                    <PermissionRequired name={"mansion.delete"}>
                        <Button
                            icon={<DeleteOutlined/>}
                            onClick={deleteMansion}
                            danger>Elimina</Button>
                    </PermissionRequired>
                </Space>
            </Col>
        </Row>
        <Descriptions column={1} layout={"vertical"} colon={false} style={{marginTop: 20}}>
            <Descriptions.Item label={"Nome"}>
                {mansion.name}
            </Descriptions.Item>
            <Descriptions.Item label={"Descrizione"}>
                {mansion.description ?? <i>Nessuna descrizione</i>}
            </Descriptions.Item>
        </Descriptions>

        <Tabs style={{marginTop: 16}} defaultActiveKey="1" items={tabs}/>
    </Spin>
}