import { Button, DatePicker, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { fiscalCodeField, requiredField } from "../common/common";
import FormPage from "../common/FormPage";
import LocaleDatePicker from "../common/LocaleDatePicker";

export default function EditEmployee() {
    const [form] = useForm();
    const [loadingData, setLoadingData] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance
            .get("employee/" + id)
            .then(({ data }) => form.setFieldsValue({ ...data, birthday: dayjs(data.birthday) }))
            .finally(() => setLoadingData(false));
    }, []);

    const update = (form) => {
        const body = { ...form, birthday: form.birthday.format("YYYY-MM-DD") };
        axiosInstance.put("employee/" + id, body).then(() => navigate(-1));
    };

    return (
        <Spin spinning={loadingData}>
            <FormPage onFinish={update} title={"Modifica dipendente"} form={form}>
                <Form.Item label={"Nome"} name={"firstName"} rules={[requiredField]} hasFeedback>
                    <Input />
                </Form.Item>

                <Form.Item label={"Cognome"} name={"lastName"} rules={[requiredField]} hasFeedback>
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"Codice fiscale"}
                    name={"fiscalCode"}
                    rules={[requiredField, fiscalCodeField]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"Data di nascita"}
                    name={"birthday"}
                    rules={[requiredField]}
                    hasFeedback
                >
                    <LocaleDatePicker />
                </Form.Item>

                <Form.Item
                    label={"Telefono"}
                    name={"phoneNumber"}
                    rules={[requiredField]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={"Telefono aziendale"}
                    name={"businessPhoneNumber"}
                    hasFeedback
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={"E-mail"}
                    name={"email"}
                    rules={[requiredField, { type: "email", message: "E-mail non valida" }]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>

                <Form.Item label={"Indirizzo"} name={"address"} rules={[requiredField]} hasFeedback>
                    <Input />
                </Form.Item>
                <Form.Item
                    label={"Matricola (paghe)"}
                    name={"externalId"}
                    hasFeedback
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "end" }}>
                    <Button type={"primary"} htmlType={"submit"}>
                        Salva modifiche
                    </Button>
                </Form.Item>
            </FormPage>
        </Spin>
    );
}
