import {Button, DatePicker, Space, Table, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import axiosInstance from "../common/api";
import {Link, useSearchParams} from "react-router-dom";
import {AttendanceMappings} from "./Attendances";
import EditNormalAttendance from "./EditNormalAttendance";

const {Text} = Typography

const {RangePicker} = DatePicker


const commonCols = [
    {
        key: 'fullName',
        title: 'Nominativo',
        fixed: 'left',
        ellipsis: true,
        dataIndex: 'fullName',
        render: (x, record) => <Link to={`/employee/${record.employeeId}`}><u>{x}</u></Link>,
        width: 150
    }
]
export default function AttendanceTabularView() {
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([])
    const query = searchParams.get("q")
    const [selectedAttendanceId, setSelectedAttendanceId] = useState()
    const range = searchParams.get("startDate") && searchParams.get("endDate") ?
        [dayjs(searchParams.get("startDate"), "YYYY-MM-DD"), dayjs(searchParams.get("endDate"), "YYYY-MM-DD")]
        :
        [dayjs().startOf('month'), dayjs().endOf('month')];

    const fetchData = () => {
        setLoading(true);
        axiosInstance.get("/attendance/tabular?startDate=" + range[0].format("YYYY-MM-DD") + "&endDate=" + range[1].format("YYYY-MM-DD") +
            (query ? "&q=" + query : ""))
            .then(({data}) => {
                setData(data.map(x => ({
                    key: x.employeeId,
                    ...x,
                    values: x.attendances.map(z => ({[z.date]: z})).reduce((a, b) => ({...a, ...b}), {})
                })))
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
    }, [searchParams])

    const columns = [
        ...commonCols,
        ...[...Array(range[1].diff(range[0], 'day') + 1).keys()]
            .map(x => ({
                key: x,
                title: range[0].add(x, 'day').format("ddd DD MMM"),
                dataIndex: 'values',
                align: 'center',
                render: (z,emp) => {
                    const date = range[0].add(x, 'day');
                    const record = z[date.format("YYYY-MM-DD")];
                    const employeeStartDate = dayjs(emp.startDate, "YYYY-MM-DD")
                    const employeeEndDate = emp.endDate ? dayjs(emp.endDate, "YYYY-MM-DD") : date.add(1, 'day')
                    if (! (employeeStartDate.isSameOrBefore(date) && date.isBefore(employeeEndDate)))
                        return <div style={{
                            background: "rgba(0, 0, 0, 0.15)",
                            color: "rgba(255, 0, 0, 0)",
                            width: '100%',
                            height: '100%'
                        }}>-</div>

                    return record ? <Tooltip
                        title={
                            <div>
                                {record?.type ? <>Tipo: {AttendanceMappings.filter(x => x.type === record.type)[0].title}<br/></> : ""}
                                {record?.startTime ? <>{record?.startTime} - {record?.endTime}<br/></> : ""}
                                {record?.travelHours ? <>Ore viaggio: {record?.travelHours}<br/></> : ""}
                                {record?.restHours ? <>Ore pausa: {record?.restHours}<br/></> : ""}
                                {record?.extra ? <>Extra: {record?.extra}<br/></> : ""}
                            </div>
                        }>
                        {record.type === 'NORMAL' ?
                            <div style={{background: !!record.endTime ? 'transparent' : 'rgba(255, 204, 0, 0.2)'}}>
                                <a type={"link"} onClick={() => setSelectedAttendanceId(record.id)}>
                                    {record.label}{(record.extra ? <><br/>({record.extra})</> : '')}
                                </a>
                            </div>
                            : record.label
                        }
                    </Tooltip> : <div style={{
                        background: "rgba(255, 0, 0, 0.15)",
                        color: "rgba(255, 0, 0, 0)",
                        width: '100%',
                        height: '100%'
                    }}>-</div>
                },
                width: 80
            }))
    ]

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <EditNormalAttendance attendanceId={selectedAttendanceId}
                              onFinish={() => {
                                  fetchData();
                                  setSelectedAttendanceId(null);
                              }}
                              onCancel={() => setSelectedAttendanceId(null)}
        />

        Legenda:
        <Space size={30}>
            <Space>
                <div style={{
                    width: 15,
                    height: 15,
                    borderRadius: 20,
                    background: 'rgba(0, 0, 0, 0.15)'
                }}/>
                <span>Non assunto</span>
            </Space>
        </Space>

        <Table loading={loading} dataSource={data} columns={columns} size={"small"} tableLayout={"fixed"}
               pagination={false}
               virtual
               bordered
               scroll={{x: 1290, y: 600}}
        />
    </div>
}
