import NewNormalAttendance from "./NewNormalAttendance";
import {AttendanceMappings} from "./Attendances";
import {useSearchParams} from "react-router-dom";
import NewRangeAttendance from "./NewGenericAttendance";

export default function () {
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type");
    const x = AttendanceMappings.filter(x => x.type === type)[0]

    const toRender = () => ({
        NORMAL: <NewNormalAttendance/>,
        ILLNESS: <NewRangeAttendance type={x.type} title={"Nuova malattia"}/>,
        HOLIDAY: <NewRangeAttendance type={x.type} title={"Nuove ferie"}/>,
        PERMIT: <NewRangeAttendance type={x.type} title={"Nuovo permesso"}/>,
        UNJUSTIFIED: <NewRangeAttendance type={x.type} title={"Nuova assenza non giustificata"}/>
    }[type])

    return toRender();
}