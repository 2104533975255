
import { Button } from "antd-mobile";
import React, { useState, useEffect, useImperativeHandle } from "react";
import Signature from "signature_pad";

function MySignaturePad({ width, height }, ref) {
    const [signaturePad, setSignaturePad] = useState(undefined);

    useEffect(() => {
        let wrapper = document.getElementById("signature-pad");
        let canvas = wrapper?.querySelector("canvas");
        const tempSignaturePad = new Signature(canvas, {
            backgroundColor: "rgb(255, 255, 255)",
            minWidth: 2,
            maxWidth: 2,
        });
        setSignaturePad(tempSignaturePad);
    }, []);

    function resizeCanvas() {
        let wrapper = document.getElementById("signature-pad");
        let canvas = wrapper?.querySelector("canvas");
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        signaturePad?.clear();
    }

    useEffect(() => {
        if (signaturePad)
            resizeCanvas();
    }, [signaturePad]);

    useImperativeHandle(ref, () => ({
        getValue: () =>
            signaturePad?.isEmpty() ? undefined : signaturePad?.toDataURL("image/png"), //svg+xml"),
        clear: () => signaturePad?.clear(),
    }));

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Button color="warning" onClick={() => signaturePad.clear()}>
                Pulisci
            </Button>
            <div
                id="signature-pad"
                style={{
                    position: "relative",
                    width: `80vw`,
                    maxWidth: '600px',
                    height: `${height}px`,
                    "-moz-user-select": "none",
                    "-webkit-user-select": "none",
                    "-ms-user-select": "none",
                    "user-select": "none",
                }}
            >
                <canvas
                    style={{
                        borderStyle: "dashed",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: `80vw`,
                        maxWidth: '600px',
                        height: `${height}px`,
                    }}
                    className="signature-canvas"
                ></canvas>
            </div>
        </div>
    );
}

export default MySignaturePad = React.forwardRef(MySignaturePad);
