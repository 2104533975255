import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Link, Outlet, RouterProvider} from "react-router-dom";
import Employees from "./employee/Employees";
import EmployeeProfile from "./employeeprofile/EmployeeProfile";
import EditEmployee from "./employeeprofile/EditEmployee";
import EditContract from "./employeeprofile/EditContract";
import EditAgreementsBenefits from "./employeeprofile/EditAgreementsBenefits";
import NewEmployee from "./employeeprofile/NewEmployee";
import NewContract from "./employeeprofile/NewContract";
import CheckLogin from "./CheckLogin";
import Courses from "./courses/Courses";
import NewCourse from "./courses/NewCourse";
import DPIReports from "./dpi/DPIReports";
import NewDPI from "./dpi/new/NewDPI";
import NewEmployeeAttachment from "./employeeprofile/attachments/NewEmployeeAttachment";
import itIT from 'antd/locale/it_IT';
import { ConfigProvider} from "antd";
import dayjs from "dayjs";
import ExportData from "./employeeprofile/ExportData";
import CoursesPlan from "./courses/CoursesPlan";
import PosForm from "./pos/PosForm";
import EditEmployeeAttachment from "./employeeprofile/attachments/EditEmployeeAttachment";
import Deadlines from "./home/Deadlines";
import Dashboard from "./dashboard/Dashboard";
import it  from 'dayjs/locale/it'
import Attendances from "./attendance/Attendances";
import NewAttendance from "./attendance/NewAttendance";
import EditNormalAttendance from "./attendance/EditNormalAttendance";
import EditCourse from "./courses/EditCourse";
import PdfViewer from "./common/PdfViewer";
import UploadPaychecks from "./paycheck/UploadPaychecks";
import Paychecks from "./paycheck/Paychecks";
import ResolvePaychecks from "./paycheck/ResolvePaychecks";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import App from "./App";
import EmployeeAttendancePage from "./employeeattendancepage/EmployeeAttendancePage";
import FetchUserInfo from "./FetchUserInfo";
import Users from "./user/Users";
import CriticalIssues from "./criticalissue/CriticalIssues";
import SimpleAttendances from "./attendance/SimpleAttendances";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import ExpenseReports from "./expensereport/ExpenseReports";
import ExpenseReport from "./expensereport/ExpenseReport";
import NewExpenseReport from "./expensereport/NewExpenseReport";
import EditExpenseReport from "./expensereport/EditExpenseReport";
import Mansions from "./mansion/Mansions";
import Mansion from "./mansion/Mansion";
import NewMansion from "./mansion/NewMansion";
import UpdateMansion from "./mansion/UpdateMansion";
const root = ReactDOM.createRoot(document.getElementById('root'));
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                index: true,
                path: "employee",
                element: <Employees/>
            },
            {
                path: "employee/:id",
                element: <EmployeeProfile/>,
            },
            {
                path: "employee/:id/edit",
                element: <EditEmployee/>
            },
            {
                path: "employee/:id/contract/edit",
                element: <EditContract/>
            },
            {
                path: "employee/:id/contract/new",
                element: <NewContract/>
            },
            {
                path: "employee/:id/agreementsbenefits/edit",
                element: <EditAgreementsBenefits/>
            },
            {
                path: "employee/new",
                element: <NewEmployee/>
            },
            {
                path: "employee/:id/attachment/new",
                element: <NewEmployeeAttachment/>
            },
            {
                path: "employee/:id/attachment/:attachmentId/edit",
                element: <EditEmployeeAttachment/>
            },
            {
                path: "courses",
                element: <Courses/>,
            },
            {
                path: "courses/new",
                element: <NewCourse/>
            },
            {
                path: "courses/:id/edit",
                element: <EditCourse/>
            },
            {
                path: "dpi",
                element: <DPIReports/>,
            },
            {
                path: "dpi/new",
                element: <NewDPI/>
            },
            {
                path: "employee/export",
                element: <ExportData/>
            },
            {
                path: "courses/plan",
                element: <CoursesPlan/>
            },
            {
                path: "pos",
                element: <PosForm/>
            },
            {
                path: "deadlines",
                element: <Deadlines/>
            },
            {
                path: "dashboard",
                element: <Dashboard/>
            },
            {
                path: "attendances",
                element: <Attendances/>
            },
            {
                path: 'simpleAttendances',
                element: <SimpleAttendances/>
            },
            {
                path: "attendances/new",
                element: <NewAttendance/>
            },
            {
                path: "attendances/:id/edit",
                element: <EditNormalAttendance/>
            },
            {
                path: "paycheck",
                element: <Paychecks/>,
            },
            {
                path: "paycheck/new",
                element: <UploadPaychecks/>
            },
            {
                path: "paycheck/resolve",
                element: <ResolvePaychecks/>
            },
            {
                path: "users",
                element: <Users/>
            },
            {
                path: "criticalissues",
                element: <CriticalIssues/>
            },
            {
                path: "expenseReports",
                element: <ExpenseReports/>
            },
            {
                path: "expenseReports/new",
                element: <NewExpenseReport/>
            },
            {
                path: "expenseReports/:id/edit",
                element: <EditExpenseReport/>
            },
            {
                path: "expenseReports/:id",
                element: <ExpenseReport/>
            },
            {
                path: "mansions",
                element: <Mansions/>
            },
            {
                path: "mansions/new",
                element: <NewMansion/>
            },
            {
                path: "mansions/:id",
                element: <Mansion/>
            },
            {
                path: "mansions/:id/edit",
                element: <UpdateMansion/>
            }
        ]
    },
    {
        path: "self",
        element: <Outlet/>,
        children: [
            {
                path: "attendance",
                element: <EmployeeAttendancePage/>
            }
        ]
    }
]);

dayjs.locale('it')

Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
}


root.render(
    <ConfigProvider locale={itIT} theme={{token: {colorPrimary: '#673ab7', colorLink: '#673ab7', }}}>
        <CheckLogin>
            <FetchUserInfo>
                <RouterProvider router={router}/>
            </FetchUserInfo>
        </CheckLogin>
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
