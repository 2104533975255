import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    DatePicker,
    Row,
    Space, Table, Tooltip,
} from 'antd';
import Search from "antd/lib/input/Search";
import {Link, useSearchParams} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import axiosInstance from "../common/api";

const {RangePicker} = DatePicker;

const commonCols = [
    {
        key: 'fullName',
        title: 'Nominativo',
        fixed: 'left',
        ellipsis: true,
        dataIndex: 'fullName',
        render: x => <u>{x}</u>,
        width: 150
    }
]

const typeColorMapping = {
    'NORMAL': 'rgba(0, 159, 37, 0.15)',
    'UNJUSTIFIED': 'orange',
    'ILLNESS': 'orange',
    'PERMIT': 'orange',
    'HOLIDAY': 'blue',
};
const typeNameMapping = {
    'NORMAL': 'Presenza',
    'ILLNESS': 'Malattia',
    'PERMIT': 'Permesso',
    'UNJUSTIFIED': 'Assenza n.g.',
    'HOLIDAY': 'Ferie',
};

const str2date = str => str && dayjs(str, "YYYY-MM-DD")

export default function () {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDateStr = searchParams.get("startDate")
    const endDateStr = searchParams.get("endDate")
    const defaultRange = [dayjs().startOf('day'), dayjs().startOf('day').add(7, 'day')]
    const r = str2date(startDateStr) && str2date(endDateStr) ? [str2date(startDateStr), str2date(endDateStr)]
        : defaultRange;
    const [searchQuery, setSearchQuery] = useState(searchParams.get("q"))
    const [range, setRange] = useState(r)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const query = searchParams.get("q")


    const fetchData = () => {
        setLoading(true);
        axiosInstance.get("/attendance/tabularSimple?startDate=" + range[0].format("YYYY-MM-DD") + "&endDate=" + range[1].format("YYYY-MM-DD") +
            (query ? "&q=" + query : ""))
            .then(({data}) => {
                setData(data.map(x => ({
                    key: x.employeeId,
                    ...x,
                    values: x.attendances.map(z => ({[z.date]: z})).reduce((a, b) => ({...a, ...b}), {})
                })))
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
    }, [searchParams])

    const columns = [
        ...commonCols,
        ...[...Array(range[1].diff(range[0], 'day') + 1).keys()]
            .map(x => ({
                key: x,
                title: range[0].add(x, 'day').format("ddd DD MMM"),
                dataIndex: 'values',
                align: 'center',
                render: z => {
                    const date = range[0].add(x, 'day')
                    const record = z[date.format("YYYY-MM-DD")];
                    const defaultColor = date.isAfter(dayjs().startOf('day')) ? 'transparent' : 'rgba(255, 0, 0, 1)'
                    return <div style={{
                        color: "transparent",
                        background: typeColorMapping[record?.value] ?? defaultColor
                    }}>-</div>
                },
                width: 80
            }))
    ]


    return <Space direction={"vertical"} style={{width: '100%'}} size={16}>
        <Row>
            <Col span={6}>
                <Title level={2} style={{margin: 0}}>Presenze</Title>
            </Col>
        </Row>
        <Row style={{marginTop: 30}}>
            <Col span={12}>
                <Space size={20} wrap>
                    <Search value={searchQuery} onChange={({target}) => setSearchQuery(target.value)}
                            placeholder="Cerca" allowClear
                            style={{width: 200}}
                            onSearch={text => {
                                searchParams.set("q", text);
                                searchParams.delete("p")
                                setSearchParams(searchParams, {replace: true})
                            }}/>
                    <RangePicker format={"DD/MM/YYYY"} value={range} onChange={(r) => {
                        if (r && r[0] && r[1]) {
                            searchParams.set("startDate", r[0].format("YYYY-MM-DD"))
                            searchParams.set("endDate", r[1].format("YYYY-MM-DD"))
                            searchParams.delete("p")
                            setRange(r);
                        } else {
                            searchParams.delete("startDate")
                            searchParams.delete("endDate")
                            searchParams.delete("p")
                            setRange(defaultRange)
                        }
                        setSearchParams(searchParams, {replace: true})
                    }}/>
                </Space>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type={"text"} danger onClick={() => {
                    setSearchParams(undefined, {replace: true})
                    setSearchQuery("");
                    setRange(defaultRange);
                }}>Cancella filtri</Button>
            </Col>
        </Row>
        Legenda:
        <Space size={30}>
            {
                [[-1, 'Assenza'], ...Object.entries(typeNameMapping)].map(([k, v]) => <Space key={k}>
                    <div style={{width: 15, height: 15, borderRadius: 20, background: typeColorMapping[k] ?? 'rgba(255, 0, 0, 1)'}}/>
                    <span>{v}</span>
                </Space>)
            }
        </Space>
        <Row style={{marginTop: 10}}>
            <Col span={24}>
                <Table loading={loading} dataSource={data} columns={columns} size={"small"} tableLayout={"fixed"}
                       sticky
                       bordered
                       pagination={false}
                       scroll={{x: 1290, y: 400}}
                />
            </Col>
        </Row>
    </Space>;
}