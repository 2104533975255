import Dragger from "antd/lib/upload/Dragger";
import DPISummary from "./DPISummary";
import Title from "antd/lib/typography/Title";
import {Form, Space} from "antd";
import TextArea from "antd/lib/input/TextArea";


export default function ({elements, employee, formId, onFinish, employees, dpiTypes}) {

    const onFormFinish = (data) => {
        const reader = new FileReader()
        reader.readAsDataURL(data.file.file)
        reader.onload = () => onFinish?.({...data, file: reader.result.split(",")[1]})
    }
    return <>
        <Title level={3}>Riepilogo</Title>
        <Space direction={"vertical"} size={30}
               style={{width: '100%'}}>
            <DPISummary elements={elements} employee={employee} employees={employees} dpiTypes={dpiTypes}/>
            <Form layout={"vertical"} id={formId} onFinish={onFormFinish}>
                <Form.Item name={"notes"} label={"Note"}>
                    <TextArea placeholder={"Inserisci eventuali note"}/>
                </Form.Item>
                <Form.Item label={"Documento firmato"} name={"file"} valuePropName={'file'}>
                    <Dragger accept={".pdf"} maxCount={1} beforeUpload={() => false}>
                        Clicca o trascina qui il file
                    </Dragger>
                </Form.Item>
            </Form>
        </Space>
    </>
}
