import { Card, DotLoading, Empty, InfiniteScroll, List, NavBar, SearchBar } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Portal } from "react-portal";

export const SelectUserPage = forwardRef(({ employees, value, onChange }, ref) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => setData(employees), [employees]);

    useImperativeHandle(ref, () => ({
        open: () => setVisible(!visible),
        close: () => setVisible(false),
    }));

    return (
        <div>
            {data.filter((x) => x.id === value)[0]?.fullName ?? "Nessun dipendente selezionato"}
            {visible && (
                <Portal>
                    <div
                        onClick={(evt) => evt.stopPropagation()}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            minWidth: "100%",
                            minHeight: "100%",
                            zIndex: 999,
                            display: "flex",
                            flexDirection: "column",
                            background: "white",
                        }}
                    >
                        <Card>
                            <NavBar
                                backArrow={null}
                                children="Seleziona dipendente"
                                right={
                                    <CloseOutline
                                        fontSize={25}
                                        onClick={() => setVisible(false)}
                                        style={{ cursor: "pointer" }}
                                    />
                                }
                            />
                            <SearchBar
                                placeholder="Cerca"
                                onSearch={(text) => {
                                    setData(
                                        employees.filter((x) =>
                                            x.fullName.toLowerCase().includes(text.toLowerCase()),
                                        ),
                                    );
                                }}
                            />
                            <List>
                                {data.map((x) => (
                                    <List.Item
                                        arrow={false}
                                        onClick={() => {
                                            onChange?.(x.id);
                                            setVisible(false);
                                        }}
                                        key={x.id}
                                    >
                                        {x.fullName}
                                    </List.Item>
                                ))}
                            </List>
                            {data.length === 0 && <Empty description="Nessun dipendente" />}
                        </Card>
                    </div>
                </Portal>
            )}
        </div>
    );
});
