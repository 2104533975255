import React, {useState, useEffect} from "react";
import Signature from "signature_pad";
import {Button, Space} from "antd";

function MySignaturePad({width, height}, ref) {
    const [signaturePad, setSignaturePad] = useState(undefined);

    console.log(width)
    useEffect(() => {
        let wrapper = document.getElementById("signature-pad");
        let canvas = wrapper?.querySelector("canvas");
        const tempSignaturePad = new Signature(canvas, {
            backgroundColor: "rgb(255, 255, 255)",
            minWidth: 2,
            maxWidth: 2
        });
        setSignaturePad(tempSignaturePad);
    }, []);

    function resizeCanvas() {
        let wrapper = document.getElementById("signature-pad");
        let canvas = wrapper?.querySelector("canvas");
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        signaturePad?.clear();
    }


    useEffect(() => {
        if (signaturePad) {
            resizeCanvas()
            if (ref)
                ref.current = {
                    getValue: () => signaturePad.isEmpty() ? undefined : signaturePad.toDataURL("image/svg+xml")
                }
        }
    }, [signaturePad]);


    return (
        <Space direction={"vertical"} style={{width: '100%'}} size={16}>
            <Button onClick={() => signaturePad.clear()}>Pulisci firma</Button>
            <div id="signature-pad" style={{
                position: "relative",
                width: `${width}px`,
                height: `${height}px`,
                "-moz-user-select": "none",
                "-webkit-user-select": "none",
                "-ms-user-select": "none",
                "user-select": "none"
            }}>
                <canvas style={{
                    borderStyle: "dashed",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: `${width}px`,
                    height: `${height}px`,
                }} width={width} height={height}
                        className="signature-canvas"></canvas>
            </div>
        </Space>
    );
}

export default MySignaturePad = React.forwardRef(MySignaturePad)
