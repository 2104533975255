import {useEffect, useState} from "react";
import {Spin} from "antd";
import App from "./App";
import {keycloack} from "./common/keycloack";


export default function CheckLogin(props) {
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        keycloack()
            .then(() => setAuthenticated(true))
    }, []);

    return authenticated ? props.children : <Spin spinning={true}/>
}