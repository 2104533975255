import {Button, Col, Form, Row, Space, Table} from "antd";
import Title from "antd/lib/typography/Title";
import TextArea from "antd/lib/input/TextArea";
import React, {useState} from "react";
import DPISummary from "./DPISummary";
import SignModal from "./SignModal";
import {useForm} from "antd/lib/form/Form";
import {requiredField} from "../../common/common";


export default function ({employee, elements, formId, onFinish, employees, dpiTypes}) {

    const [modalOpen, setModalOpen] = useState(false)
    const [form] = useForm();
    return <>
        <Title level={3}>Riepilogo</Title>
        <Space direction={"vertical"} size={30}
               style={{width: '100%'}}>
            <DPISummary dpiTypes={dpiTypes} elements={elements} employee={employee} employees={employees}/>
            <Form form={form} layout={"vertical"} id={formId} onFinish={onFinish}>
                <Form.Item name={"notes"} label={"Note"}>
                    <TextArea placeholder={"Inserisci eventuali note"}/>
                </Form.Item>
                <Button onClick={() => setModalOpen(true)}>Firma</Button>
                <Form.Item name={"signature"} rules={[{required: true,message:"Firma obbligatoria"}]}>
                    <SignModal open={modalOpen} onCancel={() => setModalOpen(false)} onOk={() => setModalOpen(false)}/>
                </Form.Item>
                {form.getFieldValue("signature") && <Form.Item label={"Firma per ricevuta"}>
                    <img src={form.getFieldValue("signature")} height={100}/>
                </Form.Item>}
            </Form>
        </Space>
    </>
}
