import {Button, Col, Popconfirm, Row, Space, Table, Tag} from "antd";
import axiosInstance from "../common/api";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {date2locale, datetime2locale, time2locale} from "../common/common";
import IconButton from "../common/IconButton";
import dayjs from "dayjs";
import {useNavigate, useSearchParams} from "react-router-dom";
import EditNormalAttendance from "./EditNormalAttendance";


const type = (enumVal) => ({
    NORMAL: <Tag color={"green"}>Presenza</Tag>,
    ILLNESS: <Tag color={"red"}>Malattia</Tag>,
    PERMIT: <Tag color={"orange"}>Permesso</Tag>,
    UNJUSTIFIED: <Tag color={"red"}>Assenza non giustificata</Tag>,
    HOLIDAY: <Tag color={"blue"}>Ferie</Tag>
}[enumVal]);
const str2date = str => str && dayjs(str, "YYYY-MM-DD")
export default function AttendanceNormalView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [result, setResult] = useState()
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAttendanceId, setSelectedAttendanceId] = useState(null)

    const page = Number(searchParams.get("p") ?? "0");
    const pageSize = Number(searchParams.get("ps") ?? "10");
    const query = searchParams.get("q")
    const sortField = searchParams.get("sortField")
    const sortOrder = searchParams.get("sortOrder")
    const startDateStr = searchParams.get("startDate")
    const endDateStr = searchParams.get("endDate")
    const r = str2date(startDateStr) && str2date(endDateStr) && [str2date(startDateStr), str2date(endDateStr)]


    const filterParams = [
        query && ("q=" + query),
        sortField && ("sortField=" + sortField),
        sortOrder && ("sortOrder=" + sortOrder),
        page && ("page=" + page),
        pageSize && ("pageSize=" + pageSize),
        r && ("startDate=" + startDateStr),
        r && ("endDate=" + endDateStr)
    ].filter(x => x).join("&")

    const fetchData = () => {
        setLoading(true)
        axiosInstance.get("attendance?" + filterParams)
            .then(res => setResult(res.data))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
    }, [searchParams]);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 70,
        },
        {
            title: 'Nominativo',
            sorter: true,
            key: 'name',
            sortOrder: sortField === 'name' ? (sortOrder === 'asc' ? 'ascend' : 'descend') : null,
            width: 200,
            fixed: 'left',
            render: (text, record) => record.employee?.fullName
        },
        {
            title: 'Data',
            sorter: true,
            key: 'date',
            dataIndex: 'date',
            sortOrder: sortField === 'date' ? (sortOrder === 'asc' ? 'ascend' : 'descend') : null,
            width: 120,
            fixed: 'left',
            render: (date) => date2locale(date)
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'Tipo',
            width: 100,
            render: type
        },
        {
            key: 'interval',
            title: 'Orario',
            width: 150,
            render: (_, record) => record.type === 'NORMAL' ? `${record?.startTime} - ${record?.endTime ?? 'In corso'}` : '-'
        },
        {
            key: 'travelHours',
            title: 'Ore viaggio',
            ellipsis: true,
            width: 100,
            render: (_, record) => record.travelHours ?? ""
        },
        {
            key: 'restHours',
            dataIndex: 'restHours',
            title: 'Ore pausa',
            ellipsis: true,
            width: 100
        },
        {
            key: 'extra',
            title: 'Extra',
            width: 100,
            render: (_, record) => record.extra ?? ""
        },
        {
            key: 'createdBy',
            title: 'Creato da',
            dataIndex: 'createdBy',
            width: 200,
            render: item => item?.firstName ? item?.firstName + " " + item?.lastName : "",
        },
        {
            key: 'creationDate',
            title: 'Data creazione',
            dataIndex: 'creationDate',
            width: 150,
            render: datetime2locale
        },
        {
            key: 'action',
            title: 'Azioni',
            width: 100,
            render: (_, record) => <Space wrap>
                {record.type === 'NORMAL' &&
                    <IconButton icon={<EditOutlined/>} tooltip={"Modifica"}
                                onClick={() => setSelectedAttendanceId(record.id)}/>}
            </Space>

        }
        /* {
             title: 'Azioni',
             key: 'action',
             dataIndex: 'id',
             render: (id) => <DownloadButton path={`course/${id}/file`}/>
         }*/
    ];
    return <>
        <Popconfirm
            title="Elimina record"
            description="Sei sicuro di voler procedere?"
            okText="Si"
            cancelText="No"
            disabled={selectedRowKeys.length === 0}
            onConfirm={() => {
                setLoading(true)
                const ids = selectedRowKeys.map(x => "id=" + x).join("&")
                axiosInstance.delete("/attendance?" + ids)
                    .then(() => setSelectedRowKeys([]))
                    .then(() => fetchData())
            }}
        >
            <Button danger type={"link"} icon={<DeleteOutlined/>}
                    disabled={selectedRowKeys.length === 0}
            >
                Elimina selezionati
            </Button>
        </Popconfirm>
        <EditNormalAttendance attendanceId={selectedAttendanceId} onFinish={() => {
            setSelectedAttendanceId(null)
            fetchData()
        }} onCancel={() => setSelectedAttendanceId(null)}/>
        <Row>
            <Col span={24}>
                <Table columns={columns}
                       tableLayout={"fixed"}
                       dataSource={result?.data.map(x => ({...x, key: x.id}))}
                       loading={loading}
                       rowSelection={{
                           selectedRowKeys: selectedRowKeys,
                           onChange: setSelectedRowKeys
                       }}
                       onChange={(pagination, filters, sorter) => {
                           searchParams.set("p", "" + (pagination.current - 1))
                           searchParams.set("ps", "" + pagination.pageSize)
                           if (sorter.order) {
                               searchParams.set("sortField", "" + sorter.columnKey)
                               searchParams.set("sortOrder", sorter.order === 'ascend' ? 'asc' : 'desc')
                           } else
                               ["sortField", "sortOrder"].forEach(x => searchParams.delete(x))
                           setSearchParams(searchParams, {replace: true})
                       }}
                       pagination={{
                           total: result?.count,
                           pageSize: pageSize,
                           current: page + 1,
                       }}
                       scroll={{x: 1290}}
                />
            </Col>
        </Row>
    </>
}