import React, {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {date2locale, monthYearFormat} from "../common/common";
import {Spin} from "antd";
import {Line} from "@ant-design/plots";
import dayjs from "dayjs";


export default function () {

    const [data, setData] = useState([])
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {
        const startDate = dayjs().subtract(1,'year').startOf('month').format("YYYY-MM-DD")
        const endDate = dayjs().endOf('month').format("YYYY-MM-DD")
        axiosInstance.get(`stats/turnoverByMonth?start=${startDate}&end=${endDate}`)
            .then(({data}) => setData(data.map(x => ({rate: Math.trunc(x.rate * 100 *100)/100, date: monthYearFormat(x.date)}))))
            .finally(() => setLoadingData(false))
    }, []);

    const config = {
        data,
        padding: 'auto',
        xField: 'date',
        yField: 'rate',
        xAxis: {
            // type: 'timeCat',
            tickCount: 5,
        },
        point: {
            size: 5
        },
        tooltip: {
            formatter: (data) => {
                return {name: 'Turnover', value: data.rate + "%"};
            },
        }
    };

    return <Spin spinning={loadingData}><Line {...config} height={200}/></Spin>;

}