import {Col, Form, Row} from "antd";
import BackTitle from "./BackTitle";


export default function FormPage({title, backButton, children, colProps, ...formProps}) {
    return <Row>
        <Col sm={{span: 14, offset: 5}} md={{span: 12, offset: 6}} lg={{span: 12, offset: 6}}
             xl={{span: 8, offset: 8}} {...colProps}>
            <BackTitle backEnabled={backButton} title={title}/>
            <Form layout={"vertical"} {...formProps}>
                {children}
            </Form>
        </Col>
    </Row>
}