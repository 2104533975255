import FormPage from "../common/FormPage";
import {Form, Input, Spin, Transfer} from "antd";
import {filterOption, requiredField} from "../common/common";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import SimpleTransfer from "../common/SimpleTransfer";
import MansionForm from "./MansionForm";
import {useNavigate} from "react-router-dom";


export default function NewMansion() {
    const navigate = useNavigate()
    const onFinish = (form) => {
        return axiosInstance
            .post('mansion',form)
            .then(({data}) => navigate(`/mansions/${data.id}`,{replace: true}))
    }
    return <MansionForm title={"Nuova mansione"} onFinish={onFinish}/>
}