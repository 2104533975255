import {Button, DatePicker, Form, Popover} from "antd";
import {SyncOutlined} from "@ant-design/icons";
import {requiredField} from "../common/common";
import {useForm} from "antd/lib/form/Form";
import {useState} from "react";
import axiosInstance from "../common/api";

const {RangePicker} = DatePicker
export default function ManualSyncButton({onFinish}) {
    const [open, setOpen] = useState(false)
    const [form] = useForm();
    const [loading, setLoading] = useState(false)
    const onFormFinish = ({range}) => {
        setLoading(true)
        const start = range[0].format("YYYY-MM-DD");
        const end = range[1].format("YYYY-MM-DD");
        const params = {
            startDate : start,
            endDate: end,
        }
        axiosInstance.post("/attendance/sync",params)
            .then(() => form.resetFields())
            .then(() => setOpen(false))
            .then(() => onFinish?.())
            .finally(() => setLoading(false))
    }
    const content = <Form form={form} onFinish={onFormFinish} layout={"vertical"}>
        <Form.Item name={"range"} label={"Data di inizio e fine"} rules={[requiredField]}>
            <RangePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item style={{textAlign: "end", marginBottom: 10}}>
            <Button type={"primary"} htmlType={"submit"} loading={loading}>Sincronizza</Button>
        </Form.Item>
    </Form>

    return <Popover open={open}
                    onOpenChange={(v) => {
                        setOpen(v);
                        form.resetFields()
                    }} content={content} title={"Scarica foglio presenze"}
                    trigger="click">
        <Button type={"link"} icon={<SyncOutlined/>} loading={loading}>Sincronizza</Button>
    </Popover>
}
