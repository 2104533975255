import {Button, theme, Tooltip} from "antd";


export default function ({style, tooltip, color, ...props}) {
    const {token} = theme.useToken();
    const c = color ?? token.colorPrimary
    return <Tooltip title={tooltip}>
        <Button
            type={"text"}
            shape={"circle"}
            {...props}
            style={{...(style ?? {}), color: c, padding: 0}}
        />
    </Tooltip>;
}