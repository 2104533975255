import React, {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {date2locale} from "../common/common";
import {Spin} from "antd";
import {Line} from "@ant-design/plots";
import dayjs from "dayjs";

export default function () {
    const [data, setData] = useState([])
    const [loadingData, setLoadingData] = useState(true)
    useEffect(() => {
        const startDate = dayjs().subtract(1,'year').startOf('month').format("YYYY-MM-DD")
        const endDate = dayjs().endOf('month').format("YYYY-MM-DD")
        axiosInstance.get(`stats/employeeCountByMonth?start=${startDate}&end=${endDate}`)
            .then(({data}) => setData(data.map(x => ({...x, date: date2locale(x.date)}))))
            .finally(() => setLoadingData(false))
    }, []);


    const config = {
        data,
        padding: 'auto',
        xField: 'date',
        yField: 'count',
        xAxis: {
            // type: 'timeCat',
            tickCount: 5,
        },
        point: {
            size: 5
        },
        tooltip: {
            formatter: (data) => {
                return { name: 'Dipendenti', value: data.count };
            },
        }
    };

    return <Spin spinning={loadingData}><Line {...config} height={200} /></Spin> ;
}