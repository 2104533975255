import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Spin, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {filterOption, requiredField} from "../common/common";
import {UploadOutlined} from "@ant-design/icons";
import axiosInstance from "../common/api";
import {useNavigate, useParams} from "react-router-dom";
import Dragger from "antd/lib/upload/Dragger";
import FormPage from "../common/FormPage";
import TextArea from "antd/lib/input/TextArea";
import {useForm} from "antd/lib/form/Form";
import PermissionRequired from "../common/PermissionRequired";
import dayjs from "dayjs";


export default function EditExpenseReport() {

    const {id} = useParams()
    const [employees, setEmployees] = useState([]);
    const [form] = useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        setPageLoading(true)
        axiosInstance.get("employeeCompact")
            .then(({data}) => setEmployees(data))

        axiosInstance.get(`expenseReport/${id}`)
            .then(({data}) => form.setFieldsValue({...data, date: dayjs(data.date), employee: data.employee.id}))
            .finally(() => setPageLoading(false))
    }, []);


    const onFormFinish = (form) => {
        setLoading(true)
        axiosInstance.put(`expenseReport/${id}`, { status: 'TO_PROCESS', ...form})
            .then(({data}) => navigate(-1))
            .finally(() => setLoading(false))
    }

    return <Spin spinning={pageLoading}>
        <FormPage
            title={"Modifica nota spese"}
            form={form}
            onFinish={onFormFinish}>
            <Form.Item name={"employee"} label={"Dipendente"} rules={[requiredField]}>
                <Select filterOption={filterOption} showSearch placeholder={"Seleziona il dipendente"}
                        options={employees.map(x => ({label: x.fullName, value: x.id}))}/>
            </Form.Item>
            <Form.Item name={"date"} label={"Data"} rules={[requiredField]}>
                <DatePicker format={"DD/MM/YYYY"} style={{width: '100%'}}/>
            </Form.Item>
            <Form.Item name={"amount"} label={"Importo"} rules={[requiredField]}>
                <InputNumber
                    stringMode
                    precision={2}
                    decimalSeparator={","}
                    prefix={"€"} style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item name={"description"} label={"Decrizione"} rules={[requiredField]}>
                <TextArea/>
            </Form.Item>

            <PermissionRequired name={"expenseReport.approval"}>
                <Form.Item name={"status"} label={"Stato"}>
                    <Select>
                        <Select.Option value={"REJECTED"}>Rifiutato</Select.Option>
                        <Select.Option value={"APPROVED"}>Approvato</Select.Option>
                        <Select.Option value={"TO_PROCESS"}>Da processare</Select.Option>
                    </Select>
                </Form.Item>
            </PermissionRequired>

            <Form.Item wrapperCol={{span: 24}} style={{textAlign: "end"}}>
                <Button htmlType={"submit"} type={"primary"} loading={loading}>Aggiorna</Button>
            </Form.Item>
        </FormPage>
    </Spin>
}