import {Modal} from "antd";
import React, {useRef} from "react";
import MySignaturePad from "./MySignaturePad";


export default function SignModal({open, onChange, onCancel, onOk}) {
    const signRef = useRef();

    return <Modal open={open} title={"Firma"} width={500}
                  onOk={() => {
                      if (signRef.current.getValue()) {
                          onChange?.(signRef.current.getValue());
                          onOk?.()
                      }
                  }}
                  onCancel={onCancel} maskClosable={false} destroyOnClose>
        <MySignaturePad width={400} height={200} ref={signRef}/>
    </Modal>
}