import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../common/api";
import MansionForm from "./MansionForm";
import {useEffect, useState} from "react";
import {Spin} from "antd";


export default function UpdateMansion(){
    const {id} = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`mansion/${id}`)
            .then(({data}) => setData({
                ...data,
                dpiTypes: data.dpiTypes.map(x=>x.id),
                attachmentTags: data.attachmentTags.map(x=>x.id),
                courseTypes: data.courseTypes.map(x=>x.id)
            }))
            .finally(() => setLoading(false))
    }, []);

    const onFinish = (form) => {
        return axiosInstance
            .put(`mansion/${id}`,form)
            .then(({data}) => navigate(-1))
    }
    return <Spin spinning={loading}>
        <MansionForm title={"Modifica mansione"} initialValues={data} onFinish={onFinish}/>
    </Spin>
}