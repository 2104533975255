import {Form} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import UploadPaychecksFile from "./UploadPaychecksFile";
import {useState} from "react";
import ConfirmPaycheck from "./ConfirmPaycheck";
import ConfirmPaychecks from "./ConfirmPaychecks";


export default function UploadPaychecks() {
    const [step, setStep] = useState(1);
    const [resultData, setResultData] = useState([])
    const [discardedPages, setDiscardedPages] = useState([]);
    return <>
        {
            discardedPages.length > 0 && (
                <span>Le pagine {discardedPages.join(", ")} sono state scartate in quanto il codice fiscale non è presente.
                Si consiglia in ogni caso di controllare</span>)
        }
        {
            step === 1 && <UploadPaychecksFile onResult={({discardedPages, paychecks}) => {
                setDiscardedPages(discardedPages)
                setResultData(paychecks)
                setStep(2)
            }}/>
        }
        {
            step === 2 && <ConfirmPaychecks data={resultData}/>
        }
    </>
}