import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../common/api";
import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    Row,
    Space,
    Spin,
    Tabs,
} from "antd";
import ContractDetails from "./ContractDetails";
import EmployeeAttachments from "./attachments/EmployeeAttachments";
import Title from "antd/lib/typography/Title";
import BackTitle from "../common/BackTitle";
import dayjs from "dayjs";
import ProfilePicture from "../common/ProfilePicture";
import { downloadFile } from "../common/common";
import { IdcardOutlined } from "@ant-design/icons";

export default function EmployeeProfile() {
    const { id } = useParams();
    const [employee, setEmployee] = useState({});
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        axiosInstance
            .get("employee/" + id)
            .then(({ data }) => setEmployee(data))
            .finally(() => setLoadingData(false));
    }, []);

    const items = [
        {
            key: "1",
            label: "Nome",
            children: employee.firstName,
        },
        {
            key: "2",
            label: "Cognome",
            children: employee.lastName,
        },
        {
            key: "3",
            label: "Codice fiscale",
            children: employee.fiscalCode,
        },
        {
            key: "4",
            label: "Data di nascita",
            children: dayjs(employee.birthday, "YYYY-MM-DD").format("DD/MM/YYYY"),
        },
        {
            key: "5",
            label: "Telefono",
            children: employee.phoneNumber,
        },
        {
            key: "6",
            label: "E-mail",
            children: employee.email,
        },
        {
            key: "7",
            label: "Indirizzo",
            children: employee.address,
        },
        {
            key: "8",
            label: "Telefono aziendale",
            children: employee.businessPhoneNumber ?? "",
        },
        {
            key: "9",
            label: "Matricola (Paghe)",
            children: employee.externalId,
        },
    ];
    const tabItems = [
        {
            key: "1",
            label: "Contratto",
            children: <ContractDetails contract={employee.contract ?? {}} />,
        },
        {
            key: "2",
            label: "Documenti",
            children: (
                <EmployeeAttachments employeeId={id} attachments={employee.attachments ?? []} />
            ),
        },
    ];
    const onTabChange = (k) => {
        searchParams.set("tab", k);
        setSearchParams(searchParams, { replace: true });
    };
    return (
        <Space direction={"vertical"} style={{ width: "100%" }} size={30}>
            <Outlet />
            <Row>
                <Col span={12}>
                    <BackTitle title={"Profilo dipendente"} level={2} padding={0} />
                </Col>
                <Col span={12} style={{textAlign: 'end'}}>
                    <Button icon={<IdcardOutlined/>} type="primary" onClick={() => downloadFile(`/employee/${employee.id}/badge`)}>
                        Stampa Badge
                    </Button>
                </Col>
            </Row>
            <Spin spinning={loadingData}>
                <>
                    <Row>
                        <Col
                            span={4}
                            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
                        >
                            <ProfilePicture employeeId={id} hasImg={!!employee.photoRef} />
                        </Col>
                        <Col span={20}>
                            <Descriptions
                                layout={"vertical"}
                                title="Anagrafica"
                                items={items}
                                extra={
                                    <Button type={"link"} onClick={() => navigate("edit")}>
                                        Modifica anagrafica
                                    </Button>
                                }
                            />
                        </Col>
                    </Row>
                    {employee.contract ? (
                        <Tabs
                            defaultActiveKey={searchParams.get("tab") ?? "1"}
                            onChange={onTabChange}
                            items={tabItems}
                        />
                    ) : (
                        <Space direction={"vertical"} style={{ width: "100%" }}>
                            <Divider />
                            <div
                                style={{
                                    width: "100%",
                                    height: 200,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "#0f0f0f0d",
                                }}
                            >
                                <Card style={{ minWidth: 200, width: "30%" }}>
                                    <Space
                                        direction={"vertical"}
                                        align={"center"}
                                        size={20}
                                        style={{ minWidth: "100%" }}
                                    >
                                        <span>Nessun contratto è stato inserito.</span>
                                        <Button
                                            onClick={() => navigate("contract/new")}
                                            type={"primary"}
                                        >
                                            Nuovo contratto
                                        </Button>
                                    </Space>
                                </Card>
                            </div>
                        </Space>
                    )}
                </>
            </Spin>
        </Space>
    );
}
