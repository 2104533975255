import {Button, Popconfirm} from "antd";
import axiosInstance from "./api";
import {DeleteOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import IconButton from "./IconButton";

export default function ({path, onFinish}) {
    const [loading, setLoading] = useState(false);

    return <Popconfirm
        title="Elimina record"
        description="Sei sicuro di voler procedere?"
        okText="Si"
        cancelText="No"
        onConfirm={() => {
            setLoading(true)
            axiosInstance.delete(path)
                .then(() => onFinish?.())
                .finally(() => setLoading(false))
        }}
    >
        <IconButton danger loading={loading} color={'red'} icon={<DeleteOutlined/>}/>
    </Popconfirm>
}
