import React, {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {Button, Checkbox, DatePicker, Form, Input, InputNumber, message, Select, Space, Spin, TimePicker} from "antd";
import FormPage from "../common/FormPage";
import {filterOption, requiredField} from "../common/common";
import {useForm} from "antd/lib/form/Form";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

const {RangePicker} = DatePicker;

export default function () {
    const [employees, setEmployees] = useState([])
    const [dataLoading, setDataLoading] = useState(true)
    const [form] = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("employeeCompact?activeOnly=false")
            .then(({data}) => setEmployees(data))
            .finally(() => setDataLoading(false))
    }, []);


    const onFormFinish = (form) => {
        const data = {
            ...form,
            startDate: form.date[0].format("YYYY-MM-DD"),
            endDate: form.date[1].format("YYYY-MM-DD"),
            startTime: form.startTime?.format("HH:mm"),
            endTime: form.endTime?.format("HH:mm"),
            attendanceType: 'NORMAL'
        }
        axiosInstance.post("attendance", data)
            .then(() => {
                navigate(-1);
                message.success("Presenza caricate correttamente")
            })
    }

    return <Spin spinning={dataLoading}>
        <FormPage title={"Nuova presenza"} form={form} onFinish={onFormFinish}>
            <Form.Item name={"employeeIds"} label={"Dipendenti"} rules={[requiredField]}>
                <Select placeholder={"Seleziona uno o più dipendenti"}
                        options={employees.map(x => ({label: x.fullName, value: x.id}))} mode={"multiple"}
                        filterOption={filterOption}/>
            </Form.Item>
            <Form.Item name={"date"} label={"Data inizio e fine"} rules={[requiredField]}>
                <RangePicker format={"DD/MM/YYYY"}/>
            </Form.Item>
            <Form.Item label={"Configurazioni predefinite"}>
                <Space wrap>
                    <Button onClick={
                        () => {
                            form.setFieldValue("startTime", dayjs("07:00", "HH:mm"))
                            form.setFieldValue("endTime", dayjs("17:00", "HH:mm"))
                            form.setFieldValue("restHours", 1)
                            form.setFieldValue("travelHours", 1)
                        }
                    }>1+8:00</Button>
                    <Button onClick={
                        () => {
                            form.setFieldValue("startTime", dayjs("08:00", "HH:mm"))
                            form.setFieldValue("endTime", dayjs("17:00", "HH:mm"))
                            form.setFieldValue("restHours", 1)
                            form.setFieldValue("travelHours", 0)
                        }
                    }>0+8:00</Button>
                </Space>
            </Form.Item>
            <Form.Item
                name={"startTime"} label={"Ora di inizio"}
                rules={[requiredField]}>
                <TimePicker format={"HH:mm"}/>
            </Form.Item>
            <Form.Item name={"endTime"} label={"Ora di fine"}
                       rules={[requiredField]}>
                <TimePicker format={"HH:mm"}/>
            </Form.Item>
            <Form.Item label={"Ore di pausa"} name="restHours"  rules={[requiredField]}>
                <InputNumber placeholder={"Inserisci la quantità"} min={0}
                             style={{width: '100%'}}/>
            </Form.Item>
            <Form.Item label={"Ore di viaggio"} name="travelHours" rules={[requiredField]}>
                <InputNumber placeholder={"Inserisci la quantità"} min={0}
                             style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item tooltip={"Il seguente campo sostituisce le ore lavorate nel foglio ore"} label={"Extra"}
                       name={"extra"}>
                <Input/>
            </Form.Item>
            {/*
                ['ILLNESS','HOLIDAY'].includes(attendanceType) && <>
                <Form.Item label={"Data d'inizio e di fine"} name={"range"} rules={[requiredField]}>
                   <RangePicker format={"DD/MM/YYYY"}/>
                </Form.Item>
                </>*/
            }
            <Form.Item style={{textAlign: "end"}}>
                <Button style={{width: 100}} htmlType={"submit"} type={"primary"}>Salva</Button>
            </Form.Item>
        </FormPage>
    </Spin>
}