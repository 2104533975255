import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Row, Space, Table, Tag, Tooltip} from 'antd';
import Search from "antd/lib/input/Search";
import axiosInstance from "../common/api";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {ExportOutlined, FileExcelOutlined, PlusOutlined, WarningTwoTone} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {downloadFile} from "../common/common";


const getParamsString = (searchParams) => {
}

export default function Employees() {
    const [mansions, setMansions] = useState([])
    const [result, setResult] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    const page = Number(searchParams.get("p") ?? "0");
    const pageSize = Number(searchParams.get("ps") ?? "10");
    const query = searchParams.get("q")
    const sortDirection = searchParams.get("sortDirection")
    const sortField = searchParams.get("sortField")
    const activeOnly = (searchParams.get("activeOnly") ?? 'true') === 'true';
    const mansionsFilter = searchParams.get("mansions")?.split(',') ?? []

    const [searchQuery, setSearchQuery] = useState(query)


    /*const filterParams = [
        "activeOnly=" + activeOnly,
        query && ("q=" + query),
        sortDirection && ("sortDirection=" + sortDirection),
        page && ("page=" + page),
        pageSize && ("pageSize=" + pageSize),
        ...mansionsFilter.map(x => "mansions=" + x)
    ].filter(x => x).join("&")*/
    const filterParams = {
        activeOnly,
        q: query,
        sortDirection,
        sortField,
        pageSize,
        page,
        mansions
    }

    useEffect(() => {
        setLoading(true)
        axiosInstance.get("mansion")
            .then((res) => setMansions(res.data))
        axiosInstance.get("employee" ,{params: filterParams})
            .then(res => {
                setResult(res.data)
                setLoading(false)
            })
    }, [searchParams]);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            sorter: true,
            sortOrder: sortField === 'id' && sortDirection ? (sortDirection === 'asc' ? 'ascend' : 'descend') : null,
            fixed: 'left',
            render: (id, record) => <Link to={"/employee/" + record.id}><u>{"E"+(""+id).padStart(3,'0')}</u></Link>,
        },
        {
            title: 'Codice fiscale',
            dataIndex: 'fiscalCode',
            fixed: 'left',
            width: 200,
            key: 'fiscalCode',
        },
        {
            title: 'Nominativo',
            sorter: true,
            key: 'name',
            ellipsis: true,
            sortOrder: sortField === 'name' && sortDirection ? (sortDirection === 'asc' ? 'ascend' : 'descend') : null,
            width: 200,

            render: (text, record) => <span>{!record.contract &&
                <Tooltip title={'Contratto mancante'}>
                    <WarningTwoTone twoToneColor='red' style={{fontSize: '1.3em'}}/>
                </Tooltip>} {`${record.lastName} ${record.firstName}`}</span>
        },
        {
            title: 'Mansioni',
            key: 'mansions',
            filters: mansions.map(x => ({text: x.name, value: x.id})),
            filterSearch: true,
            filteredValue: mansionsFilter.map(x => Number(x)),
            width: 200,
            render: (_, {contract}) => (
                <Space wrap>
                    {contract?.mansions.map(x => {
                        let color = x.title.length > 5 ? 'geekblue' : 'green';
                        //if (tag === 'loser') {
                        //    color = 'volcano';
                        //}
                        return (
                            <Tag color={color} key={x.id}>
                                {x.title}
                            </Tag>
                        );
                    })}
                </Space>
            ),
        }
    ];


    return <Space direction={"vertical"} style={{width: '100%'}} size={16}>
        <Row>
            <Col span={6}>
                <Title level={2} style={{margin: 0}}>Dipendenti</Title>
            </Col>

            <Col span={18}>
                <Space wrap style={{width: '100%', justifyContent: "end"}}
                       split={<Divider type={"vertical"}/>}>
                    <Button
                        style={{width: 200}}
                        type={"link"} icon={<ExportOutlined/>}
                        onClick={() => navigate("export")}>
                        Esporta documenti
                    </Button>
                    <Button style={{width: 200}} type={"primary"} icon={<PlusOutlined/>}
                            onClick={() => navigate("new")}>
                        Nuovo dipendente
                    </Button>
                </Space>
            </Col>
            <Col span={12}>
                <Space style={{justifyContent: "end", width: '100%'}}>
                </Space>
            </Col>
        </Row>
        <Row style={{marginTop: 30}}>
            <Col span={12}>
                <Space size={20} wrap>
                    <Search value={searchQuery} onChange={({target}) => setSearchQuery(target.value)}
                            placeholder="Cerca" allowClear
                            style={{width: 200}}
                            onSearch={text => {
                                searchParams.set("q", text);
                                searchParams.delete("p")
                                setSearchParams(searchParams, {replace: true})
                            }}/>
                    <Checkbox checked={activeOnly} onChange={({target}) => {
                        searchParams.set("activeOnly", "" + target.checked)
                        setSearchParams(searchParams, {replace: true})
                    }}>Solo attualmente assunti</Checkbox>
                </Space>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type={"text"} danger onClick={() => {
                    setSearchParams(undefined, {replace: true})
                    setSearchQuery("");
                }}>Cancella filtri</Button>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table columns={columns}
                       tableLayout={"fixed"}
                       dataSource={result?.data.map(x => ({...x, key: x.id}))}
                       loading={loading}
                       onChange={(pagination, filters, sorter) => {
                           console.log(sorter)
                           searchParams.set("p", "" + (pagination.current - 1))
                           searchParams.set("ps", "" + pagination.pageSize)
                           if (filters.mansions && filters.mansions.length > 0)
                               searchParams.set("mansions", filters.mansions.join(","));
                           else
                               searchParams.delete("mansions");
                           if (sorter.order)
                               searchParams.set("sortDirection", sorter.order === "ascend" ? "asc" : "desc")
                           else
                               searchParams.delete("sortDirection")
                            if(sorter.columnKey)
                                searchParams.set("sortField", sorter.columnKey)
                            else
                                searchParams.delete("sortField")
                           setSearchParams(searchParams, {replace: true})
                       }}
                       pagination={{
                           total: result?.count,
                           showTotal: (total) => `Totale: ${total}`,
                           pageSize: pageSize,
                           current: page + 1,
                       }}
                       scroll={{x: 700}}
                />
            </Col>
        </Row>
    </Space>;
}
