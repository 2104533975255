import {Form, Input, message, Modal, Select, Spin} from "antd";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {useForm} from "antd/lib/form/Form";
import {filterOption} from "../common/common";


export default function AssignEmployeeModal({userId, onFinish, onCancel}) {

    const [form] = useForm()
    const [employees, setEmployees] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        axiosInstance.get("employeeCompact")
            .then(({data}) => setEmployees(data))
    }, []);

    useEffect(() => {
        if (userId) {
            setPageLoading(true)
            axiosInstance.get(`user/${userId}`)
                .then(({data}) => {
                    form.setFieldsValue({
                        name: data.firstName + " " + data.lastName,
                        userId,
                        employeeId: data.employeeId
                    })
                })
                .finally(() => setPageLoading(false))

        }
    }, [userId]);

    const onFormFinish = (form) => {
        setLoading(true)
        axiosInstance.patch(`user/${userId}/employee`,form)
            .then(() => {message.success("Dipendente assegnato")})
            .then(() => onFinish?.())
            .finally(() => setLoading(false))
    }

    return <Modal open={!!userId} okButtonProps={{loading, htmlType: "submit", form: 'form'}} onCancel={onCancel}>
        <Spin spinning={pageLoading}>
            <Form form={form} layout={"vertical"} id={"form"} onFinish={onFormFinish}>
                <Form.Item name={"name"} label={"Utente"}>
                    <Input disabled/>
                </Form.Item>
                <Form.Item name={"employeeId"} label={"Dipendente"}>
                    <Select options={employees.map(x => ({label: x.fullName, value: x.id}))}
                            showSearch
                            filterOption={filterOption}/>
                </Form.Item>
            </Form>
        </Spin>
    </Modal>
}