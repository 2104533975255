import React from 'react';
import {CloseOutlined} from '@ant-design/icons';
import {Button, Card, Form, Input, InputNumber, Select, Space} from 'antd';
import dayjs from "dayjs";
import {requiredField} from "../../common/common";
import Title from "antd/lib/typography/Title";
import LocaleDatePicker from "../../common/LocaleDatePicker";

export default function NewDPIModule({initialValues, id, employees, dpiTypes, onFinish}) {
    const [form] = Form.useForm();

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (
        <Space direction={"vertical"} size={30} style={{width: '100%'}}>
            <Title level={3}>Modulo</Title>
            <Form
                layout={"vertical"}
                id={id}
                onFinish={onFinish}
                initialValues={initialValues}
                autoComplete="off"
            >
                <Form.Item hasFeedback name={"employee"} label={"Lavoratore"} rules={[requiredField]}>
                    <Select showSearch options={(employees ?? []).map(x => ({label: x.fullName, value: x.id}))}
                            placeholder={"Seleziona il lavoratore"}
                            filterOption={filterOption}/>
                </Form.Item>
                <Form.Item hasFeedback name={"date"} label={"Data di consegna"} initialValue={dayjs()}
                           rules={[requiredField, {
                               validator: async (_, value) => {
                                   if(value.isAfter(dayjs(), 'day'))
                                       throw new Error()
                               },
                               message: 'La data di consegna deve essere minore o uguale alla data odierna'
                           }]}>
                    <LocaleDatePicker/>
                </Form.Item>
                <Form.List name="elements" rules={[
                    {
                        validator: async (_, value) => {
                            if (!value || value.length === 0) {
                                throw new Error("Inserire almeno un elemento")
                            }
                        }
                    }
                ]}>
                    {(fields, {add, remove}) => (
                        <div
                            style={{
                                display: 'flex',
                                rowGap: 16,
                                flexDirection: 'column',
                            }}
                        >
                            {fields.map((field, index) => (
                                <Card
                                    size="small"
                                    title={`DPI #${field.name + 1}`}
                                    key={field.key}
                                    extra={
                                        <CloseOutlined
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    }
                                >
                                    <Form.Item hasFeedback label="Tipo DPI" name={[field.name, 'dpiType']}
                                               rules={[requiredField]}>
                                        <Select showSearch
                                                filterOption={filterOption}
                                                placeholder={"Seleziona il tipo di DPI"}
                                                options={(dpiTypes ?? []).map(x => ({label: x.name, value: x.id}))}/>
                                    </Form.Item>
                                    <Form.Item hasFeedback label={"Quantità"} name={[field.name, "quantity"]}
                                               rules={[requiredField]}>
                                        <InputNumber placeholder={"Inserisci la quantità"} min={1}
                                                     style={{width: '100%'}}/>
                                    </Form.Item>
                                    <Form.Item hasFeedback label={"Taglia"} name={[field.name, "size"]}>
                                        <Input placeholder={"Inserisci la taglia"}/>
                                    </Form.Item>
                                    <Form.Item hasFeedback label={"Marca/Modello"} name={[field.name, "brand"]}>
                                        <Input placeholder={"Inserisci la marca o il modello"}/>
                                    </Form.Item>
                                </Card>
                            ))}

                            <Form.Item style={{textAlign: "center"}}>
                                <Button type="dashed" onClick={() => add()} style={{width: 200}}>
                                    Aggiungi DPI
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form.List>
            </Form>
        </Space>
    );
};