import React, { useEffect, useState } from "react";
import { Button, Col, message, Radio, Row, Space, Steps, Table, theme } from "antd";
import axiosInstance from "../../common/api";
import NewDPIModule from "./NewDPIModule";
import { useNavigate } from "react-router-dom";
import NewDPISign from "./NewDPISign";
import NewDPIUpload from "./NewDPIUpload";
import Title from "antd/lib/typography/Title";
import { BrowserView, MobileView } from "react-device-detect";
import NewDpiMobile from "./NewDPIMobile";
import { Portal } from "react-portal";

const steps = [
    {
        title: "Selezione",
    },
    {
        title: "Compilazione",
    },
    {
        title: "Invio",
    },
];

export default function NewDPI() {
    const formId = "module-form";
    const confirmFormId = "confirm-form";

    const [employees, setEmployees] = useState([]);
    const [dpiTypes, setDpiTypes] = useState([]);

    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [moduleFormData, setModuleFormData] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [moduleType, setModuleType] = useState("DIGITAL");

    useEffect(() => {
        [
            ["employeeCompact", setEmployees],
            ["dpiType", setDpiTypes],
        ].forEach((x) => axiosInstance.get(x[0]).then(({ data }) => x[1](data)));
    }, []);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const handleDPIFinish = (data) => {
        setLoading(true);
        const toSend = { ...moduleFormData, ...data, moduleType };
        axiosInstance
            .post("dpi", toSend)
            .then(() => navigate(-1))
            .then(() => message.success("Modulo salvato con successo"))
            .finally(() => setLoading(false));
    };
    const renderSendStep = () => {
        const commonProps = {
            dpiTypes,
            employees,
            employee: moduleFormData.employee,
            elements: moduleFormData.elements,
            formId: confirmFormId,
            onFinish: handleDPIFinish,
        };
        if (moduleType === "DIGITAL") return <NewDPISign {...commonProps} />;
        else return <NewDPIUpload {...commonProps} />;
    };

    return (
        <>
            <BrowserView>
                <Row>
                    <Col
                        sm={{ span: 24 }}
                        md={{ span: 16, offset: 4 }}
                        lg={{ span: 12, offset: 6 }}
                        xxl={{ span: 8, offset: 8 }}
                    >
                        <Steps current={current} items={items} />

                        {current === 0 && (
                            <Space direction={"vertical"} size={30}>
                                <Title level={3}>Tipologia di modulo</Title>
                                <Radio.Group
                                    value={moduleType}
                                    onChange={(e) => setModuleType(e.target.value)}
                                >
                                    <Radio value={"DIGITAL"}>Digitale</Radio>
                                    <Radio value={"SCAN"}>Scansionato</Radio>
                                </Radio.Group>
                            </Space>
                        )}
                        {current === 1 && (
                            <NewDPIModule
                                id={formId}
                                employees={employees}
                                dpiTypes={dpiTypes}
                                initialValues={moduleFormData}
                                onFinish={(data) => {
                                    setModuleFormData(data);
                                    next();
                                }}
                            />
                        )}

                        {current === 2 && renderSendStep()}

                        <Space wrap style={{ width: "100%", marginTop: 30, justifyContent: "end" }}>
                            {current > 0 && (
                                <Button onClick={() => prev()} style={{ width: 150 }}>
                                    Indietro
                                </Button>
                            )}
                            {current === 0 && (
                                <Button type={"primary"} onClick={next}>
                                    Successivo
                                </Button>
                            )}
                            {current > 0 && current < steps.length - 1 && (
                                <Button
                                    type="primary"
                                    htmlType={"submit"}
                                    form={formId}
                                    style={{ width: 150 }}
                                >
                                    Successivo
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button
                                    type="primary"
                                    htmlType={"submit"}
                                    form={confirmFormId}
                                    style={{ width: 150 }}
                                    loading={loading}
                                >
                                    Invia
                                </Button>
                            )}
                        </Space>
                    </Col>
                </Row>
            </BrowserView>
            <MobileView>
                <Portal>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            minWidth: "100%",
                            minHeight: "100%",
                            zIndex: 999,
                            display: "flex",
                            background: 'white',
                            flexDirection: "column",
                        }}
                    >
                        <NewDpiMobile  dpiTypes={dpiTypes} employees={employees}/>
                    </div>
                </Portal>
            </MobileView>
        </>
    );
}
