import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {filterOption, requiredField} from "../common/common";
import {UploadOutlined} from "@ant-design/icons";
import axiosInstance from "../common/api";
import {useNavigate} from "react-router-dom";
import Dragger from "antd/lib/upload/Dragger";
import FormPage from "../common/FormPage";
import TextArea from "antd/lib/input/TextArea";


export default function NewExpenseReport() {

    const [employees, setEmployees] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axiosInstance.get("employeeCompact")
            .then(({data}) => setEmployees(data))
    }, []);


    const onFormFinish = (form) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("employee", form.employee)
        formData.append("description", form.description)
        formData.append("date", form.date.format("YYYY-MM-DD"))
        formData.append("amount", form.amount)
        formData.append("file", form.file.file)
        axiosInstance.post("expenseReport", formData, {headers: {"Content-Type": 'multipart/form-data'}})
            .then(({data}) => navigate(`/expenseReports/${data.id}`, {relative: true, replace: true}))
            .finally(() => setLoading(false))
    }

    return <FormPage
        title={"Nuova nota spese"}
        onFinish={onFormFinish}
    >
        <Form.Item name={"employee"} label={"Dipendente"} rules={[requiredField]}>
            <Select filterOption={filterOption} showSearch placeholder={"Seleziona il dipendente"}
                    options={employees.map(x => ({label: x.fullName, value: x.id}))}/>
        </Form.Item>
        <Form.Item name={"date"} label={"Data"} rules={[requiredField]}>
            <DatePicker format={"DD/MM/YYYY"} style={{width: '100%'}}/>
        </Form.Item>
        <Form.Item name={"amount"} label={"Importo"} rules={[requiredField]}>
            <InputNumber
                         stringMode
                         precision={2}
                         decimalSeparator={","}
                         prefix={"€"} style={{width: '100%'}}/>
        </Form.Item>

        <Form.Item name={"description"} label={"Decrizione"} rules={[requiredField]}>
            <TextArea/>
        </Form.Item>


        <Form.Item label={"File"} name='file' valuePropName={'file'}
                   rules={[requiredField]}>
            <Dragger accept={"application/pdf"} maxCount={1}
                     beforeUpload={() => false}>
                <p>Trascina qui il file o clicca per selezionare (Solo .pdf accettati)</p>
            </Dragger>
        </Form.Item>

        <Form.Item wrapperCol={{span: 24}} style={{textAlign: "end"}}>
            <Button htmlType={"submit"} type={"primary"} loading={loading}>Carica</Button>
        </Form.Item>

    </FormPage>
}