import {Transfer} from "antd";
import {useEffect, useState} from "react";

export default function ({value,onChange, ...otherProps}) {
    const [targetKeys, setTargetKeys] = useState([])
    const filterOption = (input, option) =>
        (option?.title ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        setTargetKeys(value)
    }, [value]);

    const handleChange = (newTargetKeys) => {
        setTargetKeys(newTargetKeys);
        onChange?.(newTargetKeys)
    }

    return <Transfer showSearch
                     targetKeys={targetKeys}
                     filterOption={filterOption}
                     listStyle={{width: '100%', height: 350}}
                     onChange={handleChange}
                     render={x => x.title}
                     {...otherProps}
    />
}