import {Table} from "antd";
import React from "react";


export default function ({employees, employee, elements, dpiTypes}){
   return <>
        <div>Dipendente: {employees.filter(x => x.id === employee)[0]?.fullName}</div>
        <Table
            columns={[
                {
                    key: 'name',
                    title: 'Tipo DPI',
                    render: (_, record) => dpiTypes.filter(x => record.dpiType === x.id)[0]?.name
                },
                {
                    key: 'quantity',
                    title: 'Quantità',
                    dataIndex: 'quantity'
                },
                {
                    key: 'size',
                    title: 'Taglia',
                    dataIndex: 'size'
                },
                {
                    key: 'brand',
                    title: 'Marca/Modello',
                    dataIndex: 'brand'
                }
            ]}
            dataSource={elements.map((x,i) => ({...x, key: i}))}
            pagination={false}
            tableLayout={"fixed"}
            size={"small"}
            bordered />
       </>
}