import { Button, Form, Grid, List, NavBar, Stepper, TextArea } from "antd-mobile";
import { SystemQRcodeOutline } from "antd-mobile-icons";
import { useRef, useState } from "react";
import { ScanPage } from "../mobile/ScanPage";
import { SelectUserPage } from "../mobile/SelectUserPage";
import dayjs from "dayjs";
import { SignaturePage } from "../mobile/SignaturePage";
import axiosInstance from "../../common/api";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

function DpiItems({ dpiTypes, value, onChange }) {
    return (
        <List>
            {dpiTypes
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                    <List.Item key={x.id}>
                        <Grid columns={2}>
                            <Grid.Item>{x.name}</Grid.Item>
                            <Grid.Item style={{ display: "flex", justifySelf: "end" }}>
                                <Stepper
                                    min={0}
                                    style={{ "--height": "40px" }}
                                    value={value?.[x.id] ?? 0}
                                    onChange={(val) => onChange?.({ ...value, [x.id]: val })}
                                />
                            </Grid.Item>
                        </Grid>
                    </List.Item>
                ))}
        </List>
    );
}

export default function NewDpiMobile({ dpiTypes, employees }) {
    const scanRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = (values) => {
        const body = {
            ...values,
            date: dayjs().format("YYYY-MM-DD"),
            moduleType: "DIGITAL",
            elements: Object.entries(values.data).map(([k, v]) => ({
                dpiType: k,
                quantity: v,
            })),
        };
        setLoading(true);
        axiosInstance
            .post("dpi", body)
            .then(() => {
                message.success("Consegna salvata correttamente");
                navigate(-1)
            })
            .finally(() => setLoading(false));
    };
    return (
        <>
            <NavBar onBack={() => navigate(-1)}>Consegna DPI</NavBar>
            <ScanPage
                ref={scanRef}
                onChange={(value) => {
                    console.log(value);
                    form.setFieldValue("employee", value);
                }}
            />

            <Button
                style={{ marginTop: 20, marginBottom: 20 }}
                color="primary"
                size="large"
                onClick={() => scanRef.current.open()}
            >
                <SystemQRcodeOutline /> Scansiona Badge
            </Button>
            <Form
                style={{ marginTop: 20 }}
                form={form}
                onFinish={onFinish}
                footer={
                    <Button block type="submit" color="primary" size="large" loading={loading}>
                        Conferma
                    </Button>
                }
            >
                <Form.Item
                    name={"employee"}
                    label="Dipendente"
                    rules={[{ required: true, message: "Dipendente campo obbligatorio" }]}
                    onClick={(e, ref) => ref.current?.open()}
                >
                    <SelectUserPage employees={employees} />
                </Form.Item>
                <Form.Item name={"data"} label="DPI">
                    <DpiItems dpiTypes={dpiTypes} />
                </Form.Item>
                <Form.Item name="notes" label="Note">
                    <TextArea rows={5} />
                </Form.Item>

                <Form.Item
                    name={"signature"}
                    label="Firma"
                    rules={[{ required: true, message: "Firma obbligatoria" }]}
                    onClick={(e, ref) => ref.current?.open()}
                >
                    <SignaturePage />
                </Form.Item>
            </Form>
        </>
    );
}
