import { FileExcelOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Modal } from "antd";
import { useState } from "react";
import { downloadFile } from "../common/common";

export default function AttendanceSummaryButton() {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false)

    const onFinish = (form) => {
        const date = form.date.format("YYYY-MM-DD")
        downloadFile(`attendance/summary?date=${date}`)
        .then(() => setShowModal(false))
        .finally(() => setLoading(false))
    }
    return (
        <>
            <Button type="link" icon={<FileExcelOutlined />} onClick={() => setShowModal(true)}>
                Riepilogo ore
            </Button>
            <Modal
                title="Scarica riepilogo ore"
                destroyOnClose
                onCancel={() => setShowModal(false)}
                cancelButtonProps={{onClick: () => setShowModal(false)}}
                open={showModal}
                okButtonProps={{ htmlType: "submit", form: "summaryForm", loading: loading }}
            >
                <Form name="summaryForm" onFinish={onFinish}>
                    <Form.Item name={"date"}>
                        <DatePicker.MonthPicker format="MM-YYYY" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
