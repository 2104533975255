import RemoteList from "../home/RemoteList";
import React, {useState} from "react";
import {Col, DatePicker, Divider, List, Row, Space} from "antd";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

const {RangePicker} = DatePicker

export default function () {
    const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')])
    const start = () => range[0].format("YYYY-MM-DD")
    const end = () => range[1].format("YYYY-MM-DD")

    return <Space direction={"vertical"} style={{width: '100%'}}>
        <Space wrap>Periodo di riferimento: <RangePicker format={"DD/MM/YYYY"} value={range} onChange={setRange}
                                                 allowClear={false}/>
        </Space>
        <Divider dashed/>

    <RemoteList path={`/stats/absenteeismRate?start=${start()}&end=${end()}`}
                renderItem={item =>
                    <List.Item>
                        <Row style={{width: '100%'}}>
                            <Col span={12}>
                                <Link to={'/employee/' + item.employeeId}><u>{item.employee}</u></Link>
                            </Col>
                            <Col span={12} style={{textAlign: "end"}}>
                                {(Math.trunc(item.rate * 100 * 100) / 100) + '%'}
                            </Col>
                        </Row>
                    </List.Item>
                }/>
</Space>
}