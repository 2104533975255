import './App.css';
import {Badge, Breadcrumb, Button, Card, Col, Divider, Layout, Menu, Row, Space, Spin, theme} from "antd";
import Sider from "antd/es/layout/Sider";
import {Content, Header} from "antd/es/layout/layout";
import {Footer} from "antd/lib/layout/layout";
import {Link, Navigate, Outlet, redirect, useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {
    AuditOutlined,
    CalendarOutlined, ClusterOutlined,
    ContainerOutlined,
    DashboardOutlined, EuroCircleOutlined, ExclamationCircleOutlined, FileDoneOutlined,
    MenuOutlined, ScheduleOutlined, ShoppingCartOutlined,
    TeamOutlined, UserOutlined
} from "@ant-design/icons";
import logo from './logo.svg';
import UserAvatar from "./common/UserAvatar";
import {hasAnyRole} from "./common/common";
import {UserInfoContext} from "./FetchUserInfo";
import axiosInstance from "./common/api";

const breadcrumbNameMap = [
    {p: /\/employee$/, t: 'Dipendenti'},
    {p: /\/employee\/\d+$/, t: 'Profilo dipendente'},
    {p: /\/employee\/export$/, t: 'Esporta documenti'},
    {p: /\/employee\/new$/, t: 'Nuovo dipendente'},
    {p: /\/employee\/\d+\/edit$/, t: 'Modifica dipendente'},
    {p: /\/employee\/\d+\/contract\/edit$/, t: 'Modifica contratto'},
    {p: /\/employee\/\d+\/contract\/new$/, t: 'Nuovo contratto'},
    {p: /\/employee\/\d+\/attachment\/new$/, t: 'Nuovo documento'},
    {p: /\/employee\/\d+\/attachment\/edit$/, t: 'Modifica documento'},
    {p: /\/courses$/, t: 'Corsi'},
    {p: /\/courses\/plan$/, t: 'Piano di formazione'},
    {p: /\/courses\/new$/, t: 'Nuovo'},
    {p: /\/courses\/\d+\/edit$/, t: 'Modifica corso'},
    {p: /\/deadlines$/, t: 'Scadenze'},
    {p: /\/dashboard$/, t: 'Dashboard'},
    {p: /\/attendances$/, t: 'Presenze'},
    {p: /\/attendances\/new$/, t: 'Nuovo'},
    {p: /\/attendances\/\d+\/edit$/, t: 'Modifica presenza'},
    {p: /\/attendances\/\d+\/edit$/, t: 'Modifica presenza'},
    {p: /\/dpi$/, t: 'DPI'},
    {p: /\/dpi\/new$/, t: 'Nuova consegna'},
    {p: /\/paycheck$/, t: 'Cedolini'},
    {p: /\/paycheck\/new$/, t: 'Caricamento cedolini'},
    {p: /\/paycheck\/resolve$/, t: 'Risoluzione problemi'},
    {p: /\/expenseReports$/, t: 'Note spese'},
    {p: /\/expenseReports\/\d+$/, t: 'Dettaglio nota spese'}
];
const getTitle = url => {
    return breadcrumbNameMap.filter(el => el.p.test(url))[0]?.t
}

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const userInfo = useContext(UserInfoContext)
    const [collapsed, setCollapsed] = useState(false);
    const [criticalIssuesCount, setCriticalIssuesCount] = useState(0)

    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const breadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        const title = getTitle(url)
        return title && {
            key: url,
            title: <Link to={url} replace>{title}</Link>,
        };
    });
    const filterItems = breadcrumbItems.filter(x => x);
    const finalItems = filterItems
        .map((x, index) => index === (filterItems.length - 1) ? ({...x, title: getTitle(x.key)}) : x)

    useEffect(() => {
        if(hasAnyRole(["admin","hr"])){
            axiosInstance.get('criticalissue')
                .then(({data}) => setCriticalIssuesCount(data.count))
        }
    }, []);


    const items = [
        {
            label: "Dashboard",
            icon: <DashboardOutlined/>,
            permissions: ["statistics.read"],
            path: "/dashboard"
        },
        {
            label: "Scadenze",
            icon: <CalendarOutlined/>,
            permissions: ["course.read", "contract.read", "employeeAttachment.read"],
            path: "/deadlines"
        },
        {
            label: <div>Criticità <Badge style={{marginLeft: 10}} count={criticalIssuesCount}/></div>,
            icon: <ExclamationCircleOutlined/>,
            permissions: ["criticalIssue.read"],
            path: "/criticalissues"
        },
        {
            label: "Dipendenti",
            icon: <TeamOutlined/>,
            permissions: ["employee.read"],
            path: "/employee"
        },
        {
            label: "Note spese",
            icon: <ShoppingCartOutlined />,
            path: "/expenseReports",
            permissions: ["expenseReport.read"]
        },
        {
            label: "Cedolini",
            icon: <EuroCircleOutlined/>,
            path: "/paycheck",
            permissions: ["paycheck.read"]
        },
        {
            label: "Presenze",
            path: "/attendances",
            icon: <ScheduleOutlined/>,
            permissions: ["attendance.read"]
        },
        {
            label: "Presenze",
            path: "/simpleAttendances",
            permissions: ["attendance.readSimple"],
            icon: <ScheduleOutlined/>
        },
        {
            label: "Corsi di formazione",
            icon: <ContainerOutlined/>,
            permissions: ["course.read"],
            path: "/courses"
        },
        {
            label: "DPI",
            icon: <AuditOutlined/>,
            permissions: ["dpiReport.read"],
            path: "/dpi"
        },
        {
            icon: <FileDoneOutlined/>,
            label: "POS",
            permissions: ["pos.create"],
            path: "/pos"
        },
        {
            label: "Utenti",
            icon: <UserOutlined/>,
            permissions: ["user.read"],
            path: "/users"
        },
        {
            label: "Mansioni",
            icon: <ClusterOutlined />,
            permissions: ["mansion.read"],
            path: "/mansions"
        }
    ].filter(x => x.permissions.every(y => userInfo.permissions?.includes(y) ?? false))
        .map((v,index)=> ({...v,key: ""+index}))

    const {
        token: {colorBgContainer},
    } = theme.useToken();


    const allowed = userInfo.roles && ["hr","admin","assistant"].some(x => userInfo.roles.includes(x))
    if(!allowed)
        return <Navigate to={'/self/attendance'} replace={true}/>
    else if(location.pathname === '/' && items.length > 0)
        return <Navigate to={items[0].path} replace={true}/>

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                trigger={null}
                collapsed={collapsed}
                onCollapse={setCollapsed}
                theme={"light"}
                width={300}
                style={{
                    zIndex: 101,
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    borderRightWidth: 1,
                    borderRightStyle: "solid",
                    borderRightColor: "#f0f0f0",
                }}
            >
                <div style={{width: '100%', textAlign: "center", marginTop: 30, marginBottom: 30}}>
                    <img alt="logo" style={{width: 50}} src={logo}/>
                </div>

                <Menu
                    mode="inline"
                    style={{height: '100%'}}
                    selectedKeys={items.filter(x => location.pathname.includes(x.path)).map(x => x.key)[0]}
                    onSelect={item => navigate(items.filter(x => x.key === item.key)[0]?.path)}
                    items={items}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        zIndex: 100,
                        position: "sticky",
                        top: 0,
                        width: "100%",
                        backgroundColor: "white",
                        boxShadow:
                            "rgba(0, 0, 0, 0.03) 0px 1px 2px 0px, rgba(0, 0, 0, 0.02) 0px 1px 6px -1px, rgba(0, 0, 0, 0.02) 0px 2px 4px 0px",
                    }}
                >

                    <div className={collapsed ? "content-collapsed" : "content"}>
                        <Row style={{verticalAlign: "middle"}}>
                            <Col span={12}>
                                <Button icon={<MenuOutlined/>} type="text" onClick={() => setCollapsed(!collapsed)}/>
                            </Col>
                            <Col span={12} style={{textAlign: "end", verticalAlign: "middle"}}>
                                {<UserAvatar user={{firstName: "Nikola", lastName: "Marku"}}/>}
                            </Col>
                        </Row>
                    </div>

                </Header>
                <Content className={collapsed ? "content-collapsed" : "content"}>
                    <div style={{margin: '16px 24px'}}>
                        <Card bodyStyle={{padding: 24}} style={{minHeight: 360}}>
                            <Space direction={"vertical"} style={{width: '100%'}}>
                                {finalItems.length > 1 && <>
                                    <Breadcrumb items={finalItems} separator={">"}/>
                                    <Divider/>
                                </>}
                                <Outlet/>
                            </Space>
                        </Card>
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>Gencarelli S.r.l. ©2023</Footer>
            </Layout>
        </Layout>
    )
}

export default App;
