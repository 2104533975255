import {useEffect, useState} from "react";
import ConfirmPaychecks from "./ConfirmPaychecks";
import {Spin} from "antd";
import axiosInstance from "../common/api";


export default function () {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axiosInstance.get("paycheck/tmp")
            .then(({data}) => setData(data))
            .finally(() => setLoading(false))
    }, []);

    return <Spin spinning={loading}>
        <ConfirmPaychecks data={data}/>
    </Spin>;
}