import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../common/api";
import {Button, Col, Descriptions, message, Row, Space, Spin, Tag} from "antd";
import {date2locale, datetime2locale, downloadFile} from "../common/common";
import BackTitle from "../common/BackTitle";
import {CheckOutlined, CloseOutlined, EditOutlined} from "@ant-design/icons";
import PermissionRequired from "../common/PermissionRequired";
import {UserInfoContext} from "../FetchUserInfo";


const stateMapping = {
    TO_PROCESS: <Tag color={'orange'}>Da approvare</Tag>,
    APPROVED: <Tag color={"green"}>Approvato</Tag>,
    REJECTED: <Tag color={"red"}>Rifiutato</Tag>
}

export default function ExpenseReport() {
    const {id} = useParams()
    const [data, setData] = useState({})
    const [pageLoading, setPageLoading] = useState(false)
    const [attachment, setAttachment] = useState("")
    const navigate = useNavigate()
    const user = useContext(UserInfoContext)

    useEffect(() => {
        setPageLoading(true)
        axiosInstance.get(`expenseReport/${id}`)
            .then(({data}) => setData(data))
            .finally(() => setPageLoading(false))
        axiosInstance.get(`expenseReport/${id}/file`, {responseType: "blob"})
            .then(({data}) => {
                const blob = new Blob([data], {type: 'application/pdf'})
                const url = URL.createObjectURL(blob);
                setAttachment(url)
            })
    }, []);

    const changeStatus = status => {
        setPageLoading(true)
        axiosInstance.patch(`expenseReport/${id}`, {status: status})
            .then(({data}) => setData(data))
            .finally(() => setPageLoading(false))
    }

    const deleteReport = () => {
        setPageLoading(true)
        axiosInstance.delete(`expenseReport/${id}`)
            .then(() => {message.success("Nota eliminata")})
            .finally(() => navigate(-1))
    }

    return <Spin spinning={pageLoading}>
        <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <BackTitle title={<>Nota spese {data.id} {data.status ? stateMapping[data.status] : ""}</>}
                           padding={0}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{textAlign: "end"}}>
                <Space align={"center"}>
                    {data.status === 'TO_PROCESS' && <PermissionRequired name={"expenseReport.approval"}>
                        <Space.Compact>
                            <Button style={{width: 120}}
                                    onClick={() => changeStatus('APPROVED')}
                                    icon={<CheckOutlined/>}>
                                Approva
                            </Button>
                            <Button style={{width: 120}}
                                    onClick={() => changeStatus('REJECTED')}
                                    icon={<CloseOutlined/>}>
                                Rifiuta
                            </Button>
                        </Space.Compact>
                    </PermissionRequired>}
                    <PermissionRequired name={"expenseReport.approval"} requireIf={data.status !== 'TO_PROCESS'}>
                        <Button style={{width: 120}} icon={<EditOutlined/>}
                                onClick={() => navigate('edit')}>Modifica</Button>
                    </PermissionRequired>
                    <PermissionRequired name={"expenseReport.delete"}>
                        <PermissionRequired name={"expenseReport.approval"}
                                            requireIf={data.createdBy?.id !== user.id || data.status !== 'TO_PROCESS'}>
                            <Button style={{width: 120}} onClick={deleteReport} danger>Elimina</Button>
                        </PermissionRequired>
                    </PermissionRequired>
                </Space>
            </Col>
        </Row>
        <Descriptions layout={"vertical"} size={"small"}
                      column={{md: 3, sm: 2, xs: 1, lg: 3, xl: 3, xxl: 3}}
                      style={{marginTop: 20}}>
            <Descriptions.Item label={"Dipendente"}>
                {data.employee?.fullName}
            </Descriptions.Item>
            <Descriptions.Item label={"Data"}>
                {data.date ? date2locale(data.date) : ""}
            </Descriptions.Item>
            <Descriptions.Item label={"Importo"}>
                {data.amount?.toLocaleString('it', {minimumFractionDigits: 2})}
            </Descriptions.Item>
            <Descriptions.Item label={"Descrizione"} span={3}>
                {data.description}
            </Descriptions.Item>
            <Descriptions.Item label={"Creato da"}>
                {data.createdBy ? `${data.createdBy.fullName} il ${datetime2locale(data.createTs)}` : ""}
            </Descriptions.Item>
            <Descriptions.Item label={"Modificato da"} span={2}>
                {data.updatedBy ? `${data.updatedBy.fullName} il ${datetime2locale(data.updateTs)}` : ""}
            </Descriptions.Item>
            <Descriptions.Item label={"Allegato"} span={3}>
                {attachment && <iframe src={attachment}
                                       width="100%"
                                       height="600px"
                />}
            </Descriptions.Item>
        </Descriptions>
    </Spin>
}