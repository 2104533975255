import BackTitle from "../common/BackTitle";
import FormPage from "../common/FormPage";
import {Button, Form} from "antd";
import {downloadFile, requiredField} from "../common/common";
import SimpleTransfer from "../common/SimpleTransfer";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";


export default function () {
    const [employees, setEmployees] = useState([])
    const [loading, setLoading ] = useState(false);

    useEffect(() => {
        axiosInstance.get("employeeCompact")
            .then(({data}) => setEmployees(data));
    }, []);

    const onFinish = (data) =>{
        setLoading(true)
        downloadFile("/course/plan", "post", data)
            .finally(() => setLoading(false))
    }

    return <FormPage title={"Scarica piano di formazione"}
                     onFinish={onFinish}
                     colProps={{xl: {span: 12, offset: 6}}}>
        <p>Sposta a destra tutti i dipendenti che vuoi includere nel piano. (Lasciando vuoto verranno automaticamente
            incluso tutto il personale) </p>
        <Form.Item name={"employeeIds"} label={"Seleziona i dipendenti"} rules={[requiredField]}>
            <SimpleTransfer dataSource={employees.map(x => ({title: x.fullName, key: x.id}))}/>
        </Form.Item>
        <Form.Item style={{textAlign: "end"}}>
            <Button type={"primary"} htmlType={"submit"}>
                Scarica
            </Button>
        </Form.Item>
    </FormPage>
}