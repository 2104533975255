import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Spin} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import axiosInstance from "../common/api";
import {filterOption, requiredField} from "../common/common";
import {useForm} from "antd/lib/form/Form";
import dayjs from "dayjs";
import {NumericFormat} from "react-number-format";
import MoneyInput from "../common/MoneyInput";


const periodOptions = ['GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO',
    'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE', 'TREDICESIMA', 'QUATTORDICESIMA']
    .map(x => ({value: x}))

export default function ConfirmPaycheck({data, onFinish}) {
    const [employees, setEmployees] = useState([])
    const [loading, setLoading] = useState(true)
    const [pdfData, setPdfData] = useState(undefined)
    const [form] = useForm();
    const [confirmLoading, setConfirmLoading] = useState(false)

    useEffect(() => {
        axiosInstance.get("employeeCompact?activeOnly=false")
            .then(({data}) => {
                setEmployees(data)
            })
    }, []);

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`paycheck/${data.id}/file?tmp=true`, {responseType: "blob"})
            .then(response => {
                const blob = new Blob([response.data], {type: "application/pdf"});
                const dataUrl = window.URL.createObjectURL(blob);
                setPdfData(dataUrl);
                form.setFieldsValue({
                    ...data,
                    hasTfr: false,
                    sendMail: true,
                })
            })
            .finally(() => setLoading(false));
    }, [data]);

    useEffect(() => {
        if (!!data && employees.length > 0)
            form.setFieldValue("email", employees.filter(x => x.id === data.employee)[0]?.email ?? undefined)
    }, [data, employees])


    useEffect(() => {
        if (!!pdfData && !!employees)
            setLoading(false)
    }, [pdfData, employees]);


    const confirmPaycheck = form => {
        setConfirmLoading(true)
        axiosInstance.put("paycheck/" + data.id, {
            ...form,
            isTmp: true
        })

            .then(() => onFinish?.())
            .finally(() => setConfirmLoading(false))
    }

    const deletePaycheck = () => {
        axiosInstance.delete("paycheck/" + data.id + "?tmp=true")
            .then(() => onFinish?.())
    }

    return <Spin spinning={loading}>
        <Row gutter={20}>
            <Col span={16}>
                <iframe src={pdfData} style={{width: '100%', height: 700}}>ciao</iframe>
            </Col>
            <Col span={8}>
                <Form layout={"vertical"}
                      form={form}
                      onFinish={confirmPaycheck}>
                    <Form.Item name={"employee"} label={"Dipendente"} rules={[requiredField]}>
                        <Select filterOption={filterOption} showSearch placeholder={"Seleziona il dipendente"}
                                onChange={z =>
                                    form.setFieldValue("email", employees.filter(x => x.id === z)[0]?.email ?? undefined)
                                }
                                options={employees.map(x => ({label: x.fullName, value: x.id}))}/>
                    </Form.Item>
                    <Form.Item label={"Periodo"} name={"period"} rules={[requiredField]}>
                        <Select showSearch options={periodOptions}/>
                    </Form.Item>
                    <Form.Item label={"Anno"} name={"year"}>
                        <InputNumber decimalSeparator={""}/>
                    </Form.Item>
                    <Form.Item label={"Importo lordo"} name={"gross"} rules={[requiredField]}>
                        <MoneyInput/>
                    </Form.Item>
                    <Form.Item name={"hasTfr"} valuePropName={'checked'} rules={[requiredField]}>
                        <Checkbox>Questo cedolino include TFR</Checkbox>
                    </Form.Item>
                    <Form.Item label={"Indirizzo email"} name={"email"}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item rules={[requiredField]} name={"sendMail"} valuePropName={'checked'}>
                        <Checkbox>Invia cedolino via e-mail</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Space wrap>
                            <Popconfirm title={"Operazione irreversibile"}
                                        style={{maxWidth: 250}}
                                        description={"Il cedolino verrà eliminato. Confermare?"}
                                        onConfirm={deletePaycheck}
                            >
                                <Button>Scarta</Button>
                            </Popconfirm>
                            <Button loading={confirmLoading}
                                    type={"primary"}
                                    htmlType={"submit"}>Conferma e vai al successivo</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    </Spin>
}