import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {Col, List, Row, Tag} from "antd";
import {Link} from "react-router-dom";
import {date2locale, daysLeft, renderDaysLeft} from "../common/common";
import RemoteList from "./RemoteList";

export default function (){

    return <RemoteList
        path={"contract"}
        renderItem={(item, index) => (
            <List.Item>
                <Row style={{width: '100%'}}>
                    <Col span={12}>
                        <Link to={"/employee/"+item.employeeId}><u>{item.employeeName}</u></Link>
                    </Col>
                    <Col span={12} style={{textAlign: "end"}}>
                        {renderDaysLeft(item.dueDate)}
                    </Col>
                </Row>
            </List.Item>
        )}
    />
}