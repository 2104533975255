import {Button, DatePicker, Form, Radio, Select, Spin} from "antd";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import FormPage from "../common/FormPage";
import {useForm} from "antd/lib/form/Form";
import {date2locale, requiredField} from "../common/common";
import LocaleDatePicker from "../common/LocaleDatePicker";

const date2dayjs = (d) => d ? dayjs(d, "YYYY-MM-DD") : undefined

const getInitialValues = (c) => ({
    startDate: date2dayjs(c.startDate),
    terminationDate: date2dayjs(c.terminationDate),
    terminationReason: c.terminationReason?.id,
    dueDate: date2dayjs(c.dueDate),
    contractType: c.contractType?.id,
    mansions: c.mansions?.map(x => x.id) ?? [],
    hasTravelHour: c.hasTravelHour
})

export default function EditContract() {
    const [contractTypes, setContractTypes] = useState([])
    const [terminationReasons, setTerminationReasons] = useState([])
    const [mansions, setMansions] = useState([])
    const [contractTypeDueDate, setContractTypeDueDate] = useState(false)
    const navigate = useNavigate();
    const [showTermReason, setShowTermReason] = useState(false)
    const [loadingData, setLoadingData] = useState(true)
    const [form] = useForm()
    const {id} = useParams()


    const enableDueDateIfRequired = (v) => {
        const dueDate = contractTypes.filter(x => x.id === v)[0]?.hasDueDate || false
        setContractTypeDueDate(dueDate)
    }

    useEffect(() => {
        [{p: "contractType", f: setContractTypes},  {
            p: "mansion",
            f: setMansions
        }, {
            p: "terminationReason",
            f: setTerminationReasons
        }].forEach(x => axiosInstance.get(x.p).then(({data}) => x.f(data)))
        axiosInstance.get("employee/" + id)
            .then(({data}) => {
                form.setFieldsValue(getInitialValues(data.contract))
                setShowTermReason(!!data.contract.terminationDate)
            })
            .finally(() => setLoadingData(false))
    }, []);


    useEffect(() => {
        if (contractTypes && !!form.getFieldValue("contractType"))
            enableDueDateIfRequired(form.getFieldValue("contractType"))
    }, [contractTypes, form.getFieldValue("contractType")]);


    const onFormFinish = (f) => {
        const data = {
            ...f,
            startDate: f.startDate?.format("YYYY-MM-DD"),
            dueDate: f.dueDate?.format("YYYY-MM-DD"),
            endDate: f.startDate?.format("YYYY-MM-DD"),
            terminationDate: f.terminationDate?.format("YYYY-MM-DD"),
        }
        axiosInstance.put(`employee/${id}/contract`, data)
            .then(() => navigate(-1));
    }


    return <Spin spinning={loadingData}>
        <FormPage
            title={"Modifica contratto"}
            onFinish={onFormFinish}
            form={form}>
            <Form.Item hasFeedback label={"Data assunzione"} name={"startDate"} rules={[requiredField]}>
                <LocaleDatePicker/>
            </Form.Item>
            <Form.Item hasFeedback label={"Data cessazione"} name={"terminationDate"}>
                <LocaleDatePicker onChange={v => setShowTermReason(!!v)}/>
            </Form.Item>
            {showTermReason &&
                <Form.Item hasFeedback label={"Motivo cessazione"} name={"terminationReason"} rules={[requiredField]}>
                    <Select allowClear options={terminationReasons.map(x => ({label: x.title, value: x.id}))}/>
                </Form.Item>}
            <Form.Item hasFeedback label={"Tipo contratto"} name={"contractType"} rules={[requiredField]}>
                <Select onSelect={enableDueDateIfRequired}
                        options={contractTypes.map(x => ({label: x.type, value: x.id}))}/>
            </Form.Item>
            {contractTypeDueDate && (
                <Form.Item hasFeedback label={"Data di scadenza contratto"} name={"dueDate"} rules={[requiredField]}>
                    <LocaleDatePicker/>
                </Form.Item>)}
            <Form.Item hasFeedback label={"Mansioni"} name={"mansions"} rules={[requiredField]}>
                <Select mode={"multiple"} options={mansions.map(x => ({label: x.name, value: x.id}))}/>
            </Form.Item>
            <Form.Item hasFeedback label={"Ora di viaggio"} name={"hasTravelHour"} rules={[requiredField]}>
                <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item hasFeedback wrapperCol={{span: 24}} style={{textAlign: 'end'}}>
                <Button type={"primary"} htmlType={"submit"}>Salva modifiche</Button>
            </Form.Item>
        </FormPage>
    </Spin>
}
