import {Button, Descriptions, Space, Tag} from "antd";
import {useNavigate} from "react-router-dom";
import {date2locale} from "../common/common";


export default function ContractDetails({contract}) {
    const navigate = useNavigate();
    const items = [
        {
            key: '1',
            label: 'Data assunzione',
            children: date2locale(contract.startDate),
        },
        {
            key: '2',
            label: 'Data cesssazione',
            children: contract.terminationDate ? date2locale(contract.terminationDate) :  '-',
        },
        {
            key: '3',
            label: 'Motivo cessazione',
            children: contract.terminationReason?.title ?? '-',
        },
        {
            key: '5',
            label: 'Tipo contratto',
            children: contract.contractType?.type,
        },
        {
            key: '6',
            label: 'Scadenza contratto',
            children: contract.dueDate ? date2locale(contract.dueDate) : '-'
        },
        {
            key: '7',
            label: 'Mansioni',
            children: <div style={{minWidth: '100%'}}>
                {contract.mansions?.map(x => <Tag key={x.id}>{x.title}</Tag>)}
            </div>,
        },
        {
            key: '8',
            label: 'Ora di viaggio',
            children: contract.hasTravelHour ? 'Si' : 'No'
        }
    ].filter(x=> !!x)
    return <Descriptions layout={"vertical"} items={items}
                         extra={<Button type={"link"} onClick={() => navigate("contract/edit")}>
                             Modifica contratto
                         </Button>}/>
}