import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../common/api";
import dayjs from "dayjs";
import {
    Badge,
    Button,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    InputNumber,
    message,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
} from "antd";
import IconButton from "../common/IconButton";
import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    FileExcelOutlined,
    MoreOutlined,
    PlusOutlined,
    SendOutlined,
} from "@ant-design/icons";
import DownloadButton from "../common/DownloadButton";
import RoleRendering from "../common/RoleRendering";
import DeleteIconButton from "../common/DeleteIconButton";
import Title from "antd/lib/typography/Title";
import Search from "antd/lib/input/Search";
import { hasAnyRole } from "../common/common";
import PdfViewer from "../common/PdfViewer";

const str2date = (str) => str && dayjs(str, "YYYY-MM-DD");

const periodOptions = [
    "GENNAIO",
    "FEBBRAIO",
    "MARZO",
    "APRILE",
    "MAGGIO",
    "GIUGNO",
    "LUGLIO",
    "AGOSTO",
    "SETTEMBRE",
    "OTTOBRE",
    "NOVEMBRE",
    "DICEMBRE",
    "TREDICESIMA",
    "QUATTORDICESIMA",
].map((x) => ({ value: x }));

export default function Paychecks() {
    const [result, setResult] = useState();
    const [info, setInfo] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const [attachment, setAttachment] = useState(null);

    const page = Number(searchParams.get("p") ?? "0");
    const pageSize = Number(searchParams.get("ps") ?? "10");
    const query = searchParams.get("q");
    const sortField = searchParams.get("sortField");
    const sortOrder = searchParams.get("sortOrder");
    const period = searchParams.get("period");

    const [searchQuery, setSearchQuery] = useState(query);
    const [periodValue, setPeriodValue] = useState(period);

    const filterParams = [
        query && "q=" + query,
        sortField && "sortField=" + sortField,
        sortOrder && "sortOrder=" + sortOrder,
        period && "period=" + period,
        page && "page=" + page,
        pageSize && "pageSize=" + pageSize,
    ]
        .filter((x) => x)
        .join("&");

    const fetch = () => {
        setLoading(true);
        return axiosInstance.get("paycheck?" + filterParams).then((res) => {
            setResult(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        axiosInstance.get("paycheck/info").then(({ data }) => setInfo(data));
    }, []);
    useEffect(() => {
        fetch();
    }, [searchParams]);

    useEffect(() => {
        if (reload) fetch().finally(() => setReload(false));
    }, [reload]);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            fixed: "left",
            width: 70,
        },
        {
            title: "Nominativo",
            sorter: true,
            fixed: "left",
            ellipsis: true,
            key: "name",
            sortOrder: sortField === "name" ? (sortOrder === "asc" ? "ascend" : "descend") : null,
            width: 150,

            render: (text, record) => `${record.employee.lastName} ${record.employee.firstName}`,
        },
        {
            title: "Periodo",
            sorter: true,
            key: "period",
            sortOrder: sortField === "period" ? (sortOrder === "asc" ? "ascend" : "descend") : null,
            ellipsis: true,
            width: 150,
            render: (_, record) => record.period + " " + record.year,
        },
        {
            title: "Importo lordo",
            key: "gross",
            dataIndex: "gross",
            width: 150,
            align: "right",
            render: (x) => "€ " + new Intl.NumberFormat("it-IT").format(x),
        },
        {
            title: "TFR",
            key: "tfr",
            width: 70,
            dataIndex: "hasTfr",
            render: (x) => (x ? "Si" : "No"),
        },
        {
            title: "Stato invio",
            key: "mailState",
            dataIndex: "mailState",
            width: 100,
            sorter: true,
            sortOrder:
                sortField === "mailState" ? (sortOrder === "asc" ? "ascend" : "descend") : null,
            ellipsis: true,
            align: "center",
            render: (x) => {
                switch (x) {
                    case "NOT_SENT":
                        return (
                            <Tooltip title={"Non inviata"}>
                                <CloseOutlined />
                            </Tooltip>
                        );
                    case "SENDING":
                        return (
                            <Tooltip title={"Invio in corso"}>
                                <ClockCircleOutlined />
                            </Tooltip>
                        );
                    case "SENT":
                        return (
                            <Tooltip title={"Inviata"}>
                                <CheckOutlined />
                            </Tooltip>
                        );
                }
            },
        },
        {
            title: "Azioni",
            key: "action",
            dataIndex: "id",
            width: 150,
            render: (id) => (
                <Space wrap>
                    <IconButton
                        tooltip={"Visualizza"}
                        onClick={() => setAttachment(encodeURI(`/paycheck/${id}/file`))}
                        icon={<EyeOutlined />}
                    />
                    <DownloadButton path={`paycheck/${id}/file`} />
                    <Dropdown
                        trigger={"click"}
                        menu={{
                            items: [
                                {
                                    key: "1",
                                    icon: <SendOutlined />,
                                    label: (
                                        <a
                                            onClick={() =>
                                                axiosInstance
                                                    .post(`paycheck/${id}/mail`)
                                                    .then(() =>
                                                        message.success(
                                                            "Richiesta eseguita con successo. L'operazione potrebbe richiedere alcuni minuti",
                                                        ),
                                                    )
                                            }
                                        >
                                            Invia come email
                                        </a>
                                    ),
                                },
                                hasAnyRole(["admin", "hr"]) && {
                                    key: "3",
                                    icon: <DeleteOutlined style={{ color: "red" }} />,
                                    label: (
                                        <Popconfirm
                                            title={"Operazione irreversibile"}
                                            description={"Il cedolino verrà eliminato. Confermare?"}
                                            onConfirm={() => {
                                                axiosInstance.delete("paycheck/" + id).then(() => {
                                                    message.success("Cedolino eliminato");
                                                    setReload(true);
                                                });
                                            }}
                                        >
                                            <a style={{ color: "red" }}>Elimina</a>
                                        </Popconfirm>
                                    ),
                                },
                            ].filter((x) => !!x),
                        }}
                    >
                        <IconButton icon={<MoreOutlined />} />
                    </Dropdown>
                </Space>
            ),
        },
    ];

    return (
        <Space direction={"vertical"} style={{ width: "100%" }} size={16}>
            <PdfViewer url={attachment} onClose={() => setAttachment(null)} />
            <Row>
                <Col span={6}>
                    <Title level={2} style={{ margin: 0 }}>
                        Cedolini
                    </Title>
                </Col>
                <Col span={18}>
                    <Space
                        wrap
                        style={{ width: "100%", justifyContent: "end" }}
                        split={<Divider type={"vertical"} />}
                    >
                        <RoleRendering forRoles={["hr", "admin"]}>
                            <Button
                                type={"primary"}
                                icon={<PlusOutlined />}
                                style={{ width: 200 }}
                                onClick={() => navigate("new")}
                            >
                                Carica cedolini
                            </Button>
                        </RoleRendering>
                    </Space>
                </Col>
            </Row>
            {info.requiresAttentionCount > 0 && (
                <Space>
                    <Button type={"link"} onClick={() => navigate("resolve")}>
                        <Space>
                            <Badge count={info.requiresAttentionCount} />
                            elementi richiedono attenzione
                        </Space>
                    </Button>
                </Space>
            )}
            <Row style={{ marginTop: 30 }}>
                <Col span={12}>
                    <Space size={20} wrap>
                        <Search
                            value={searchQuery}
                            onChange={({ target }) => setSearchQuery(target.value)}
                            placeholder="Cerca"
                            allowClear
                            style={{ width: 200 }}
                            onSearch={(text) => {
                                searchParams.set("q", text);
                                searchParams.delete("p");
                                setSearchParams(searchParams, { replace: true });
                            }}
                        />
                        <Select
                            placeholder={"Periodo"}
                            value={periodValue}
                            style={{ width: 200 }}
                            allowClear
                            showSearch
                            options={periodOptions}
                            onChange={(x) => {
                                setPeriodValue(x);
                                if (x) searchParams.set("period", x);
                                else searchParams.delete("period");
                                searchParams.delete("p");
                                setSearchParams(searchParams);
                            }}
                        />
                    </Space>
                </Col>
                <Col span={12} style={{ textAlign: "end" }}>
                    <Button
                        type={"text"}
                        danger
                        onClick={() => {
                            setSearchParams(undefined, { replace: true });
                            setSearchQuery("");
                            setPeriodValue(undefined);
                        }}
                    >
                        Cancella filtri
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        tableLayout={"fixed"}
                        dataSource={result?.data.map((x) => ({ ...x, key: x.id }))}
                        loading={loading}
                        onChange={(pagination, filters, sorter) => {
                            searchParams.set("p", "" + (pagination.current - 1));
                            searchParams.set("ps", "" + pagination.pageSize);

                            if (sorter.order) {
                                searchParams.set("sortField", "" + sorter.columnKey);
                                searchParams.set(
                                    "sortOrder",
                                    sorter.order === "ascend" ? "asc" : "desc",
                                );
                            } else
                                ["sortField", "sortOrder"].forEach((x) => searchParams.delete(x));
                            setSearchParams(searchParams, { replace: true });
                        }}
                        pagination={{
                            total: result?.count,
                            pageSize: pageSize,
                            current: page + 1,
                        }}
                        scroll={{ x: 840 }}
                    />
                </Col>
            </Row>
        </Space>
    );
}
