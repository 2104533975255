import {Button, Form, Transfer} from "antd";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import FormPage from "../common/FormPage";
import SimpleTransfer from "../common/SimpleTransfer";
import {downloadFile, requiredField} from "../common/common";
import {useSearchParams} from "react-router-dom";


export default function () {
    const [searchParams] = useSearchParams();
    const [employees, setEmployees] = useState([])
    const [courseTypes, setCourseTypes] = useState([])
    const [tags, setTags] = useState([])
    const [dpiTypes, setDpiTypes] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        [
            {u: "courseType", s: setCourseTypes}, {u: "employeeCompact", s: setEmployees},
            {u: "tag", s: setTags}, {u: "dpiType", s: setDpiTypes}
        ].forEach(({u, s}) => axiosInstance.get(u).then(({data}) => s(data)))
    }, []);

    const onFinish = (form) => {
        setLoading(true)
        downloadFile("export", "post", form)
            .finally(() => setLoading(false))
    }
    return <>
        <FormPage
            onFinish={onFinish}
            colProps={{xl: {span: 12, offset: 6}}}
            backButton={!searchParams.get("e")}
            initialValues={{
                employees: searchParams.getAll("e").map(Number) ?? []
            }}
            title={"Esporta file ZIP"}>
            <p>Sposta a destra tutti i campi che vuoi includere nell'archivio</p>
            <Form.Item name={"employees"} label={"Seleziona i dipendenti"} rules={[requiredField]}>
                <SimpleTransfer dataSource={employees.map(x => ({title: x.fullName, key: x.id}))}/>
            </Form.Item>

            <Form.Item name={"courseTypes"} label={"Seleziona le tipologie di corsi"}>
                <SimpleTransfer dataSource={courseTypes.map(x => ({key: x.id, title: x.title}))}/>
            </Form.Item>

            <Form.Item name={"dpiTypes"} label={"Seleziona le tipologie di DPI"}>
                <SimpleTransfer dataSource={dpiTypes.map(x => ({key: x.id, title: x.name}))}/>
            </Form.Item>

            <Form.Item name={"attachmentTags"} label={"Seleziona le tipologie documenti"}>
                <SimpleTransfer dataSource={tags.map(x => ({key: x.id, title: x.name}))}/>
            </Form.Item>
            <Form.Item style={{textAlign: 'end'}}>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>Scarica</Button>
            </Form.Item>
        </FormPage>
    </>
}