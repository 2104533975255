import {NumericFormat} from "react-number-format";
import {Input} from "antd";
import React from "react";


export default function MoneyInput(props) {
    const propsCopy = JSON.parse(JSON.stringify(props))
    delete propsCopy.onChange;

    return <NumericFormat
        customInput={Input}
        prefix={"€ "}
        thousandSeparator={"."}
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        onValueChange={x => props.onChange(x.floatValue)}
        isAllowed={(values) => {
            const {formattedValue, floatValue} = values;
            return formattedValue === "" || floatValue >= 0;
        }}
        {...propsCopy}
    />
}