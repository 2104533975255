import {Button, Checkbox, DatePicker, Form, Input, Radio, Select, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {requiredField} from "../common/common";
import {UploadOutlined} from "@ant-design/icons";
import axiosInstance from "../common/api";
import {useNavigate} from "react-router-dom";
import Dragger from "antd/lib/upload/Dragger";
import FormPage from "../common/FormPage";


export default function NewCourse() {

    const [employees, setEmployees] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        [{u: "courseType", f: setCourseTypes}, {u: "employeeCompact", f: setEmployees}].forEach(x => {
            axiosInstance.get(x.u)
                .then(({data}) => x.f(data))
        });
    }, []);

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onFormFinish = (form) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("employee", form.employee)
        formData.append("courseType", form.courseType)
        formData.append("date", form.date.format("YYYY-MM-DD"))
        formData.append("isBase", form.isBase ?? false)
        formData.append("file", form.file.file)
        axiosInstance.post("course", formData, {headers: {"Content-Type": 'multipart/form-data'}})
            .then(() => navigate(-1))
    }
    return <FormPage
        title={"Carica nuovo corso"}
        onFinish={onFormFinish}
        initialValues={{isBase: false}}
    >
        <Form.Item name={"employee"} label={"Dipendente"} rules={[requiredField]}>
            <Select filterOption={filterOption} showSearch placeholder={"Seleziona il dipendente"}
                    options={employees.map(x => ({label: x.fullName, value: x.id}))}/>
        </Form.Item>
        <Form.Item name={"courseType"} label={"Nome corso"} rules={[requiredField]}>
            <Select filterOption={filterOption} showSearch placeholder={"Seleziona il tipo di corso"}
                    options={courseTypes.map(x => ({label: x.title, value: x.id}))}/>
        </Form.Item>
        <Form.Item name={"isBase"} label={"Tipo di attestato"}>
            <Radio.Group>
                <Radio value={true}>Base</Radio>
                <Radio value={false}>Aggiornamento</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item name={"date"} label={"Data"} rules={[requiredField]}>
            <DatePicker format={"DD/MM/YYYY"} style={{width: '100%'}}/>
        </Form.Item>

        <Form.Item label={"File"} name='file' valuePropName={'file'}
                   rules={[requiredField]}>
            <Dragger accept={".png,.jpg, .jpeg, application/pdf"} maxCount={1}
                     beforeUpload={() => false}>
                <p>Trascina qui il file (Solo .pdf accettati)</p>
                <Button icon={<UploadOutlined/>}>Seleziona file</Button>
            </Dragger>
        </Form.Item>

        <Form.Item wrapperCol={{span: 24}} style={{textAlign: "end"}}>
            <Button htmlType={"submit"} type={"primary"} loading={loading}>Carica</Button>
        </Form.Item>

    </FormPage>
}