import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {List, Spin} from "antd";


export default function ({renderItem, path}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        axiosInstance.get(path)
            .then(({data}) => setData(data.data))
            .finally(() => setLoading(false))
    }, [path]);
    return <Spin spinning={loading}>
        <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={renderItem}
        />
    </Spin>
}