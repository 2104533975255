import { Button, Space, Table, Tag } from "antd";
import dayjs from "dayjs";
import NewEmployeeAttachment from "./NewEmployeeAttachment";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../common/api";
import {  useNavigate } from "react-router-dom";
import { CloudDownloadOutlined, EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import IconButton from "../../common/IconButton";
import { date2locale, downloadFile } from "../../common/common";
import DownloadButton from "../../common/DownloadButton";
import PdfViewer from "../../common/PdfViewer";

const toDayjs = (date) => dayjs(date ?? "1970-01-01", "YYYY-MM-DD");

export default function ({ employeeId, attachments }) {
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();
    const [selectedAttachment, setSelectedAttachment] = useState(null);

    const columns = (employeeId, tags) => [
        {
            key: "title",
            dataIndex: "title",
            title: "Titolo",
            fixed: "left",
            width: 200,
        },
        {
            key: "date",
            dataIndex: "date",
            render: (v) => v && date2locale(v),
            title: "Data",
            width: 150,
            sorter: (a, b) => toDayjs(a.date).diff(toDayjs(b.date)),
        },
        {
            key: "dueDate",
            dataIndex: "dueDate",
            render: (v) => v && date2locale(v),
            title: "Data di scadenza",
            ellipsis: true,
            width: 150,
            sorter: (a, b) => toDayjs(a.dueDate).diff(toDayjs(b.dueDate)),
        },
        {
            key: "createdBy",
            title: "Creato da",
            dataIndex: "createdBy",
            width: 200,
            render: (createdBy) => createdBy.firstName + " " + createdBy.lastName,
        },
        {
            key: "tag",
            dataIndex: "attachmentTag",
            title: "Etichette",
            filters: tags,
            onFilter: (value, record) => value === record.attachmentTag.id,
            filterSearch: true,
            width: 250,
            render: (v) => <Tag color={v.color}>{v.name}</Tag>,
        },
        {
            key: "id",
            title: "Azioni",
            dataIndex: "id",
            width: 150,
            render: (id) => (
                <Space wrap={true}>
                    <IconButton
                        icon={<EyeOutlined />}
                        onClick={() =>
                            setSelectedAttachment(`employee/${employeeId}/attachment/${id}`)
                        }
                    />
                    <DownloadButton path={`employee/${employeeId}/attachment/${id}`} />
                    <IconButton
                        icon={<EditOutlined />}
                        onClick={() => navigate(`attachment/${id}/edit`)}
                    />
                </Space>
            ),
        },
    ];

    useEffect(() => {
        axiosInstance.get("/tag").then(({ data }) => setTags(data));
    }, []);

    return (
        <>
            <PdfViewer url={selectedAttachment} onClose={() => setSelectedAttachment(null)} />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <Button
                    style={{ alignSelf: "end" }}
                    type={"primary"}
                    icon={<PlusOutlined />}
                    onClick={() => navigate("attachment/new")}
                >
                    Nuovo
                </Button>
                <Table
                    tableLayout={"fixed"}
                    columns={columns(
                        employeeId,
                        tags.map((x) => ({ text: x.name, value: x.id })),
                    )}
                    dataSource={attachments.map((x) => ({ ...x, key: x.id }))}
                    scroll={{ x: 1190 }}
                    pagination={{ showSizeChanger: true }}
                />
            </div>
        </>
    );
}
