import {Button, Form, Spin} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import {requiredField} from "../common/common";
import axiosInstance from "../common/api";
import {useState} from "react";
import FormPage from "../common/FormPage";

export default function ({onResult}) {

    const [loading, setLoading] = useState(false);
    const onFinish = data => {
        setLoading(true)
        const formData = new FormData();
        formData.append("file", data.file.file)
        axiosInstance.post("paycheck", formData, {headers: {"Content-Type": 'multipart/form-data'}})
            .then(({data}) => onResult?.(data))
            .finally(() => setLoading(false))
    }
    return <Spin spinning={loading}>
        <FormPage title={"Caricamento cedolini"} backButton={false} onFinish={onFinish}>
            <div style={{marginBottom: 20}}>
                È possibile caricare un file unico. Il file caricato potrà contenere uno o
                più cedolini al suo interno, il sistema si occuperà di separarli correttamente
            </div>
            <Form.Item name={"file"} label={"File"} valuePropName={'file'} rules={[requiredField]}>
                <Dragger accept={"application/pdf"} maxCount={1}
                         beforeUpload={() => false}>
                    Clicca qui per caricare oppure trascina il file.
                </Dragger>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button htmlType={"submit"} type={"primary"}>
                    Carica
                </Button>
            </Form.Item>
        </FormPage>
    </Spin>;
}