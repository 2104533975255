import {Button, DatePicker, Form, Input, Select, Spin} from "antd";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {useNavigate, useParams} from "react-router-dom";
import FormPage from "../common/FormPage";
import {useForm} from "antd/lib/form/Form";

const getInitialValues = (c) => ({
    agreements: c.agreements?.map(x => x.id) ?? [],
    benefits: c.benefits?.map(x => x.id) ?? [],
    netMonthlyBase: c.netMonthlyBase
})

export default function EditAgreementsBenefits() {
    const [loadingData, setLoadingData] = useState(true)
    const [agreements, setAgreements] = useState([]);
    const [benefits, setBenefits] = useState([])
    const navigate = useNavigate();
    const [form] = useForm();
    const {id} = useParams()

    useEffect(() => {
        [
            {p: "agreement", f: setAgreements},
            {p: "benefit", f: setBenefits},
        ].forEach(x => axiosInstance.get(x.p).then(({data}) => x.f(data)))
        axiosInstance.get("employee/" + id)
            .then(({data}) => form.setFieldsValue(getInitialValues(data.contract)))
            .finally(() =>setLoadingData(false))
    }, []);

    const onFormFinish = (f) => {
        axiosInstance.put(`employee/${id}/agreementsbenefits`, f)
            .then(() => navigate(-1));
    }


    return <Spin spinning={loadingData}>
        <FormPage
            onFinish={onFormFinish}
            form={form}
            title={"Modifica Accordi e Benefit"}>

            <Form.Item label={"Accordi"} name={"agreements"}>
                <Select mode={"multiple"}
                        options={agreements.map(x => ({label: `${x.title} - ${x.description}`, value: x.id}))}/>
            </Form.Item>
            <Form.Item label={"Benefits"} name={"benefits"}>
                <Select mode={"multiple"} options={benefits.map(x => ({label: x.title, value: x.id}))}/>
            </Form.Item>

            <Form.Item label={"Netto mensile"} name={"netMonthlyBase"}>
                <Input prefix={"€"}/>
            </Form.Item>

            <Form.Item wrapperCol={{span: 24}} style={{textAlign: 'end'}}>
                <Button type={"primary"} htmlType={"submit"}>Salva modifiche</Button>
            </Form.Item>
        </FormPage>
    </Spin>
}