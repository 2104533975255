import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {Button, DatePicker, Form, message, Select, Spin, TimePicker} from "antd";
import FormPage from "../common/FormPage";
import {filterOption, requiredField} from "../common/common";
const {RangePicker} = DatePicker;


export default function ({title,type, ...otherProps}) {
    const [employees, setEmployees] = useState([])
    const [dataLoading, setDataLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("employeeCompact?activeOnly=false")
            .then(({data}) => setEmployees(data))
            .finally(() => setDataLoading(false))
    }, []);


    const onFormFinish = (form) => {
        const data = {
            ...form,
            attendanceType: type,
            startDate: form.range?.[0]?.format("YYYY-MM-DD"),
            endDate: form.range?.[1]?.format("YYYY-MM-DD")
        }
        axiosInstance.post("attendance", data)
            .then(() => {
                navigate(-1);
                message.success( "caricamento avvenuto correttamente")
            })
    }

    return <Spin spinning={dataLoading}>
        <FormPage title={title} onFinish={onFormFinish}>
            <Form.Item name={"employeeId"} label={"Dipendente"} rules={[requiredField]}>
                <Select placeholder={"Seleziona dipendente"}
                        options={employees.map(x => ({label: x.fullName, value: x.id}))}
                        showSearch
                        filterOption={filterOption}/>
            </Form.Item>
            <Form.Item label={"Data d'inizio e di fine"} name={"range"} rules={[requiredField]}>
                <RangePicker format={"DD/MM/YYYY"}/>
            </Form.Item>

            <Form.Item style={{textAlign: "end"}}>
                <Button  style={{width: 100}} htmlType={"submit"} type={"primary"}>Salva</Button>
            </Form.Item>
        </FormPage>
    </Spin>
}