import axiosInstance from "./api";
import dayjs from "dayjs";
import {keycloakInstance} from "./keycloack";

export const requiredField = {
    required: true,
    message: 'Campo obbligatorio'
}

export const fiscalCodeField =
    {
        pattern: /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
        message: "Codice fiscale non valido"
    };

export const date2locale = (d) => dayjs(d, "YYYY-MM-DD").format("DD/MM/YYYY");
export const datetime2locale = (d) => dayjs(d).format("DD/MM/YYYY HH:mm");
export const daysLeft = (d) => dayjs(d,"YYYY-MM-DD").diff(dayjs().startOf('day'),'days');

export const  renderDaysLeft = (d) => {
    const dayjsDate = dayjs(d,"YYYY-MM-DD");
    const left = dayjsDate.diff(dayjs().startOf('day'),'days')
    const colors = [{days: 14, color: 'red'}, {days: 30, color: '#ffa000'}, {days:60, color: 'green'}]
    const selectedColor = colors.filter(x => left <= x.days)[0]?.color ?? 'black';
    return <span style={{color : selectedColor}}>{dayjsDate.format("DD/MM/YYYY")} ({left} giorni)</span>
}
export const downloadFile = (path, method, body) => {
    const fun = {
        "get": () => axiosInstance.get(path, {responseType: "blob"}),
        "post": () => axiosInstance.post(path, body, {responseType: "blob"})
    }[method ?? "get"];
    return fun()
        .then(response => {
            const href = URL.createObjectURL(response.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', response.headers.get("Content-Disposition").split("filename=")[1]); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
}

export const monthYearFormat = strDate => dayjs(strDate, "YYYY-MM-DD").format("MMMM YYYY")

export const hasAnyRole = (roles) => roles.map(keycloakInstance.hasRealmRole).reduce((a,b) => a || b);



export const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


export const hasVerticalScrollbar = (el) =>  {
    console.log(`${el.clientHeight} ${window.innerHeight}`)
    return el.clientHeight > window.innerHeight
}