import React, {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import axiosInstance from "../common/api";
import {Button, Col, Divider, Row, Space, Table, Tag, Tooltip} from "antd";
import Title from "antd/lib/typography/Title";
import Search from "antd/lib/input/Search";
import {date2locale} from "../common/common";
import {FileExcelOutlined, PlusOutlined} from "@ant-design/icons";
import RoleRendering from "../common/RoleRendering";
import PermissionRequired from "../common/PermissionRequired";


export default function ExpenseReports() {
    const [result, setResult] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    const page = Number(searchParams.get("page") ?? "0");
    const pageSize = Number(searchParams.get("pageSize") ?? "10");
    const query = searchParams.get("query")
    const stateMapping = {
        TO_PROCESS: <Tag color={'orange'}>Da approvare</Tag>,
        APPROVED: <Tag color={"green"}>Approvato</Tag>,
        REJECTED: <Tag color={"red"}>Rifiutato</Tag>
    }
    const [searchQuery, setSearchQuery] = useState(query)

    const params = {
        query: query,
        page: page,
        pageSize: pageSize
    }

    const fetch = () => {
        setLoading(true)
        return axiosInstance.get("expenseReport", {params})
            .then(res => {
                setResult(res.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        fetch()
    }, [searchParams]);


    const columns = [
        {
            title: 'ID',
            ellipsis: true,
            key: 'id',
            dataIndex: 'id',
            width: 70,
            render: x => <Link to={`${x}`}>{x}</Link>
        },
        {
            title: 'Dipendente',
            key: 'employee',
            dataIndex: 'employee',
            ellipsis: true,
            width: 200,
            render: x => x.fullName
        },
        {
            title: 'Data',
            key: 'date',
            dataIndex: 'date',
            width: 120,
            ellipsis: true,
            render: date2locale
        },
        {
            title: 'Descrizione',
            key: 'description',
            dataIndex: 'description',
            ellipsis: true,
            width: 200,
            render: x => <Tooltip title={x}>{x}</Tooltip>
        },
        {
            title: 'Importo',
            key: 'amount',
            dataIndex: 'amount',
            ellipsis: true,
            width: 100,
            render: x => x.toLocaleString('it', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        {
            title: 'Stato',
            key: 'status',
            dataIndex: 'status',
            width: 100,
            render: x => stateMapping[x]
        }
    ];

    return <Space direction={"vertical"} style={{width: '100%'}} size={16}>
        <Row>
            <Col span={6}>
                <Title level={2} style={{margin: 0}}>Note spese</Title>
            </Col>
            <Col span={18}>
                <Space wrap style={{width: '100%', justifyContent: "end"}}
                       split={<Divider type={"vertical"}/>}>
                    <PermissionRequired name={"expenseReport.create"}>
                        <Button type={"primary"} icon={<PlusOutlined/>}
                                style={{width: 200}}
                                onClick={() => navigate("new")}>
                            Nuova nota
                        </Button>
                    </PermissionRequired>
                </Space>
            </Col>
        </Row>
        <Row style={{marginTop: 30}}>
            <Col span={12}>
                <Space size={20} wrap>
                    <Search value={searchQuery} onChange={({target}) => setSearchQuery(target.value)}
                            placeholder="Cerca" allowClear
                            style={{width: 200}}
                            onSearch={text => {
                                searchParams.set("query", text);
                                searchParams.delete("page")
                                setSearchParams(searchParams, {replace: true})
                            }}/>
                </Space>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type={"text"} danger onClick={() => {
                    setSearchParams(undefined, {replace: true})
                    setSearchQuery("");
                }}>Cancella filtri</Button>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table columns={columns}
                       tableLayout={"fixed"}
                       dataSource={result?.data.map(x => ({...x, key: x.id}))}
                       loading={loading}
                       onChange={(pagination, filters, sorter) => {
                           searchParams.set("page", "" + (pagination.current - 1))
                           searchParams.set("pageSize", "" + pagination.pageSize)
                           setSearchParams(searchParams, {replace: true})
                       }}
                       pagination={{
                           total: result?.count,
                           showTotal: count => `Totale: ${count}`,
                           pageSize: pageSize,
                           current: page + 1,
                       }}
                       scroll={{x: columns.reduce((acc, x) => x.width + acc, 0)}}
                />
            </Col>
        </Row>
    </Space>;
}