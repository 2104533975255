import IconButton from "./IconButton";
import {CloudDownloadOutlined} from "@ant-design/icons";
import {downloadFile} from "./common";
import React, {useState} from "react";


export default function DownloadButton({path}){
    const [loading,setLoading] = useState(false);
    return <IconButton icon={<CloudDownloadOutlined/>}
                       tooltip={"Scarica"}
                       loading={loading}
                onClick={() => {
                    setLoading(true)
                    downloadFile(path).finally(() => setLoading(false))
                }}/>
}