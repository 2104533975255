import React, {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {
    Button,
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Space,
    Spin,
    TimePicker
} from "antd";
import {requiredField} from "../common/common";
import {useForm} from "antd/lib/form/Form";
import dayjs from "dayjs";
import LocaleDatePicker from "../common/LocaleDatePicker";


export default function ({attendanceId, onFinish, onCancel}) {
    const [dataLoading, setDataLoading] = useState(true)
    const [form] = useForm();
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const fillForm = data => {
        form.setFieldsValue({
            ...data,
            employee: data.employee.fullName,
            date: dayjs(data.date, "YYYY-MM-DD"),
            startTime: dayjs(data.startTime, "HH:mm"),
            endTime: data.endTime ? dayjs(data.endTime, "HH:mm") : null
        })
    }

    useEffect(() => {
        setOpen(attendanceId != null && attendanceId !== undefined)
    },[attendanceId]);

    useEffect(() => {
        if(open){
            setDataLoading(true)
            //document.body.style.overflowY = hasVerticalScrollbar(document.body) ? 'hidden' : 'scroll'
            axiosInstance.get("attendance/" + attendanceId)
                .then(({data}) => fillForm(data))
                .finally(() => setDataLoading(false))
        }
    },[open])


    const onFormFinish = (form) => {
        setLoading(true)
        const data = {
            ...form,
            date: form.date?.format("YYYY-MM-DD"),
            startTime: form.startTime?.format("HH:mm"),
            endTime: form.endTime?.format("HH:mm"),
            attendanceType: 'NORMAL'
        }
        axiosInstance.put("attendance/" + attendanceId, data)
            .then(() => {
                message.success("Presenza modificata correttamente")
            })
            .then(onFinish)
            .finally(() => setLoading(false));
    }

    return <Modal className={'disable-animation'} title={"Modifica presenza"} open={open} okText={"Salva"}
                  okButtonProps={{form: 'form', htmlType: "submit", loading: loading}}
                  onCancel={onCancel}>
        <Spin spinning={dataLoading}>
            <Form id={'form'} form={form} onFinish={onFormFinish} layout={"vertical"}>
                <Form.Item label={"Dipendente"} name={"employee"}>
                    <Input disabled/>
                </Form.Item>
                <Form.Item name={"date"} label={"Data"} rules={[requiredField]}>
                    <LocaleDatePicker disabled/>
                </Form.Item>
                <Form.Item label={"Configurazioni predefinite"}>
                    <Space wrap>
                        <Button onClick={
                            () => {
                                form.setFieldValue("startTime", dayjs("07:00", "HH:mm"))
                                form.setFieldValue("endTime", dayjs("17:00", "HH:mm"))
                                form.setFieldValue("restHours", 1)
                                form.setFieldValue("travelHours", 1)
                            }
                        }>1+8:00</Button>
                        <Button onClick={
                            () => {
                                form.setFieldValue("startTime", dayjs("08:00", "HH:mm"))
                                form.setFieldValue("endTime", dayjs("17:00", "HH:mm"))
                                form.setFieldValue("restHours", 1)
                                form.setFieldValue("travelHours", 0)
                            }
                        }>0+8:00</Button>
                    </Space>
                </Form.Item>
                <Form.Item name={"startTime"} label={"Ora di inizio"}
                           rules={[requiredField]}>
                    <TimePicker format={"HH:mm"}/>
                </Form.Item>
                <Form.Item name={"endTime"} label={"Ora di fine"}
                           rules={[requiredField]}>
                    <TimePicker format={"HH:mm"}/>
                </Form.Item>

                <Form.Item label={"Ore di pausa"} name="restHours" rules={[requiredField]}>
                    <InputNumber placeholder={"Inserisci la quantità"} min={0}
                                 style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item label={"Ore di viaggio"} name="travelHours" rules={[requiredField]}>
                    <InputNumber placeholder={"Inserisci la quantità"} min={0}
                                 style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item tooltip={"Il seguente campo sostituisce le ore lavorate nel foglio ore"} label={"Extra"}
                           name={"extra"}>
                    <Input/>
                </Form.Item>
                {/*
                ['ILLNESS','HOLIDAY'].includes(attendanceType) && <>
                <Form.Item label={"Data d'inizio e di fine"} name={"range"} rules={[requiredField]}>
                   <RangePicker format={"DD/MM/YYYY"}/>
                </Form.Item>
                </>*/
                }
            </Form>
        </Spin>
    </Modal>
}
