import {useEffect, useState} from "react";
import axiosInstance from "../common/api";
import {Col, List, Row, Tag} from "antd";
import {Link} from "react-router-dom";
import {date2locale, daysLeft, renderDaysLeft} from "../common/common";
import dayjs from "dayjs";
import RemoteList from "./RemoteList";

export default function (){

    const getDueDate = (record) => dayjs(record.date, 'YYYY-MM-DD')
        .add(record.courseType.yearsDuration, 'year')
        .format('YYYY-MM-DD');

    return <RemoteList
        path={"course?activeOnly=true&sortField=dueDate&sortOrder=asc&maxOnly=true&onlyActiveEmployees=true"}
        renderItem={(item, index) => (
            <List.Item>
                <Row style={{width: '100%'}}>
                    <Col span={8}>
                        <Link to={"/employee/"+item.employee.id}><u>{item.employee?.fullName}</u></Link>
                    </Col>
                    <Col span={8}>
                        {item.courseType?.title}
                    </Col>
                    <Col span={8} style={{textAlign: "end"}}>
                        {renderDaysLeft(getDueDate(item))}
                    </Col>
                </Row>
            </List.Item>
        )}
    />
}