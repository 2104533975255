import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Row, Select, Space, Table, Tag, Tooltip} from 'antd';
import Search from "antd/lib/input/Search";
import axiosInstance from "../common/api";
import {Link, Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {
    CloudDownloadOutlined,
    DownloadOutlined,
    DownOutlined,
    EyeOutlined,
    PlusOutlined,
    WarningTwoTone
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import {downloadFile, filterOption} from "../common/common";
import IconButton from "../common/IconButton";
import DownloadButton from "../common/DownloadButton";
import FilterTags from "../common/FilterTags";
import PdfViewer from '../common/PdfViewer';


export default function DPIReports() {
    const [result, setResult] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const [openReport, setOpenReport] = useState(null)
    const navigate = useNavigate();
    const [dpiTypes, setDpiTypes] = useState([])

    const page = Number(searchParams.get("p") ?? "0");
    const pageSize = Number(searchParams.get("ps") ?? "10");
    const query = searchParams.get("q")
    const sortField = searchParams.get("sortField")
    const sortOrder = searchParams.get("sortOrder")
    const dpiType = searchParams.get("dpiType") && parseInt(searchParams.get("dpiType"))

    const [searchQuery, setSearchQuery] = useState(query)

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Tipo dispositivo',
                key: 'name',
                render: (_, r) => r.dpiType.name
            },
            {
                title: 'Marca/Modello',
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: 'Quantità',
                key: 'quantity',
                dataIndex: 'quantity'
            },
            {
                title: 'Taglia',
                dataIndex: 'size',
                key: 'size',
            }
        ];
        return <Table bordered size={"small"} columns={columns}
                      dataSource={record.elements.map(x => ({...x, key: x.id}))} pagination={false}/>;
    }


    const filterParams = [
        query && ("q=" + query),
        sortField && ("sortField=" + sortField),
        sortOrder && ("sortOrder=" + sortOrder),
        page && ("page=" + page),
        pageSize && ("pageSize=" + pageSize),
        dpiType && ("dpiType=" + dpiType)
    ].filter(x => x).join("&")

    useEffect(() => {
        setLoading(true)
        axiosInstance.get("dpi?" + filterParams)
            .then(res => {
                setResult(res.data)
                setLoading(false)
            })
    }, [searchParams]);

    useEffect(() => {
        axiosInstance.get("dpiType")
            .then(({data}) => setDpiTypes(data))
    }, []);


    const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
                width: 70,
            },
            {
                title: 'Nominativo',
                sorter:
                    true,
                key:
                    'name',
                fixed: 'left',
                ellipsis: true,
                sortOrder:
                    sortField === 'name' ? (sortOrder === 'asc' ? 'ascend' : 'descend') : null,
                width: 200,

                render:
                    (text, record) => `${record.employee.lastName} ${record.employee.firstName}`
            },
            {
                title: 'Data',
                sorter: true,
                key: 'date',
                sortOrder:
                    sortField === 'date' ? (sortOrder === 'asc' ? 'ascend' : 'descend') : null,
                width: 120,
                render: (_, record) => dayjs(record.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            },
            {
                title: "Consegnato da",
                key: "createdBy",
                width: 150,
                ellipsis: true,
                render: (_, record) => `${record.createdBy.firstName} ${record.createdBy.lastName}`
            },
            {
                title: 'Azioni',
                key: 'action',
                dataIndex: 'id',
                width: 100,
                render: (id) => <Space wrap>
                    <IconButton tooltip={"Visualizza"}
                                onClick={() => setOpenReport(encodeURI(`/dpi/${id}/attachment`))}
                                icon={<EyeOutlined/>}/>
                    <DownloadButton path={`/dpi/${id}/attachment`}/>
                </Space>
            }
        ]
    ;


    return <Space direction={"vertical"} style={{width: '100%'}} size={16}>
        <PdfViewer url={openReport} onClose={() => setOpenReport(null)}/>
        <Row>
            <Col span={12}>
                <Title level={2} style={{margin: 0}}>DPI</Title>
            </Col>
            <Col span={12}>
                <Space style={{justifyContent: "end", width: '100%'}}>
                    <Button style={{width: 200}} type={"primary"} icon={<PlusOutlined/>}
                            onClick={() => navigate("new")}>
                        Nuova consegna
                    </Button>
                </Space>
            </Col>
        </Row>
        <Row style={{marginTop: 30}}>
            <Col span={12}>
                <Space size={20} wrap>
                    <Search value={searchQuery} onChange={({target}) => setSearchQuery(target.value)}
                            onBlur={(e) => {
                                setSearchQuery(e.target.value)
                                searchParams.set("q", ""+e.target.value);
                                searchParams.delete("p")
                                setSearchParams(searchParams, {replace: true})
                            }}
                            placeholder="Cerca" allowClear
                            style={{width: 200}}
                            onSearch={text => {
                                searchParams.set("q", text);
                                searchParams.delete("p")
                                setSearchParams(searchParams, {replace: true})
                            }}/>
                    <Select value={dpiType} allowClear showSearch placeholder={"Tipo DPI"} style={{width: 250}}
                            filterOption={filterOption}
                            onChange={(v) => {
                                if (v)
                                    searchParams.set("dpiType", v);
                                else searchParams.delete("dpiType")
                                searchParams.delete("p")
                                setSearchParams(searchParams, {replace: true})
                            }} options={dpiTypes.map(x => ({label: x.name, value: x.id}))}/>
                </Space>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type={"text"} danger onClick={() => {
                    setSearchParams(undefined, {replace: true})
                    setSearchQuery("");
                }}>Cancella filtri</Button>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <FilterTags
                    filters={[
                        query && {id: "q", label: query},
                        dpiType && {id: "dpiType", label: dpiTypes.filter(x => x.id === dpiType)[0]?.name},
                    ].filter(x => !!x)}
                    onClose={x => {
                        searchParams.delete(x);
                        if(x === 'q') setSearchQuery("")
                        setSearchParams(searchParams, {replace: true})
                    }}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table columns={columns}
                       tableLayout={"fixed"}
                       dataSource={result?.data.map(x => ({...x, key: x.id}))}
                       loading={loading}
                       onChange={(pagination, filters, sorter) => {
                           searchParams.set("p", "" + (pagination.current - 1))
                           searchParams.set("ps", "" + pagination.pageSize)
                           if (sorter.order) {
                               searchParams.set("sortField", "" + sorter.columnKey)
                               searchParams.set("sortOrder", sorter.order === 'ascend' ? 'asc' : 'desc')
                           } else
                               ["sortField", "sortOrder"].forEach(x => searchParams.delete(x))
                           setSearchParams(searchParams, {replace: true})
                       }}
                       pagination={{
                           total: result?.count,
                           pageSize: pageSize,
                           current: page + 1,
                       }}
                       scroll={{x: 640}}
                       expandable={{expandedRowRender, defaultExpandedRowKeys: ['0']}}
                />
            </Col>
        </Row>
    </Space>;
}
