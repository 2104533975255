import {Button, Divider, Popover,Typography, Space} from "antd";
import {useContext, useEffect, useState} from "react";
import axiosInstance from "./api";
import {LogoutOutlined} from "@ant-design/icons";
import {keycloakInstance} from "./keycloack";
import {UserInfoContext} from "../FetchUserInfo";

const {Text} = Typography;


export default function (){
    const user= useContext(UserInfoContext);
    const [popoverOpen, setPopoverOpen] = useState(false)


    const logoutAction = () => {
       keycloakInstance.logout({redirectUri: 'http://localhost:8080/'});
    }

    const popoverContent = <Space
            size={1}
            direction={"vertical"}
            style={{
                width: 270,
            }}
        >
            <Space direction={"vertical"} size={0}>
                <Text>{(user?.["firstName"] ?? "") + " " + (user?.["lastName"] ?? "")}</Text>
                <Text type={"secondary"}>{user?.["email"] ?? ""}</Text>
            </Space>
            <Divider dashed={true} style={{marginTop: 15, marginBottom: 15}}/>
            <Button
                style={{minWidth: "100%", textAlign: "start"}}
                icon={<LogoutOutlined/>}
                danger
                type={"text"}
                onClick={logoutAction}
            >
                Logout
            </Button>
        </Space>

    return <Popover
        open={popoverOpen}
        onOpenChange={setPopoverOpen}
        placement="bottomRight"
        content={popoverContent}
        trigger="click">
        <Button
            size="large"
            shape="circle"
            type={"text"}
            style={{backgroundColor: 'coral'}}
            icon={
                <div style={{color: "white"}}>
                    {(user?.["firstName"]?.at(0).toUpperCase() ?? "") +
                        (user?.["lastName"]?.at(0).toUpperCase() ?? "")}
                </div>
            }
        ></Button>
    </Popover>
}