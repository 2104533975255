import {Button, Col, DatePicker, Form, Input, message, Row, Select} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import axiosInstance from "../../common/api";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Title from "antd/lib/typography/Title";
import FormPage from "../../common/FormPage";
import LocaleDatePicker from "../../common/LocaleDatePicker";
import {requiredField} from "../../common/common";
import {useForm} from "antd/lib/form/Form";


export default function () {
    const navigate = useNavigate();
    const {id} = useParams();
    const [tags, setTags] = useState([])
    const [selectedTag, setSelectedTag] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axiosInstance.get("tag")
            .then(({data}) => setTags(data))
    }, []);

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onFinish = (form) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("title", form.title)
        form.date && formData.append("date", form.date.format("YYYY-MM-DD"))
        form.dueDate && formData.append("dueDate", form.dueDate.format("YYYY-MM-DD"))
        formData.append("file", form.file.file)
        formData.append("tag", form.tag)
        axiosInstance.post(`employee/${id}/attachment`,
            formData,
            {headers: {"Content-Type": 'multipart/form-data'}})
            .then(() => navigate(-1))
            .then(() => message.success("Documento caricato con successo"))
            .finally(() => setLoading(false))
    }

    return <FormPage onFinish={onFinish} title={"Carica nuovo documento"}>
        <Form.Item hasFeedback name={"title"} label={"Titolo"} rules={[requiredField]}>
            <Input/>
        </Form.Item>

        <Form.Item hasFeedback name={"tag"} label={"Etichetta"} rules={[requiredField]}>
            <Select filterOption={filterOption} showSearch
                    onChange={value => setSelectedTag(tags.filter(x => x.id === value)[0])}
                    options={(tags ?? []).map(x => ({label: x.name, value: x.id}))}/>
        </Form.Item>
        <Form.Item hasFeedback name={"date"} label={"Data"}
                   rules={[selectedTag?.requireDate && requiredField].filter(x => !!x)}>
            <LocaleDatePicker/>
        </Form.Item>
        <Form.Item hasFeedback name={"dueDate"} label={"Data scadenza"}
                   rules={[selectedTag?.requireDueDate && requiredField,
                       ({getFieldValue}) => ({
                           validator(_, value) {
                               const date = getFieldValue('date')
                               if (!!value && !!date && value.diff(date, 'day') < 1) {
                                   return Promise.reject(new Error('La data di scadenza deve essere dopo la data del documento'));
                               }
                               return Promise.resolve();
                           },
                       }),
                   ].filter(x => !!x)}>
            <LocaleDatePicker/>
        </Form.Item>
        <Form.Item hasFeedback name={"file"} label={"File"} rules={[requiredField]} valuePropName={'file'}>
            <Dragger accept={".png,.jpg, .jpeg, application/pdf"}
                     maxCount={1} beforeUpload={() => false}>Trascina qui il file o clicca per selezionare</Dragger>
        </Form.Item>
        <Form.Item style={{textAlign: "end"}}>
            <Button type={"primary"} htmlType={"submit"} style={{width: 150}} loading={loading}>Carica</Button>
        </Form.Item>
    </FormPage>
}
